<template>
  <div class="directory-display">
    <div class="directory-header">
      <div v-if="$route.name === 'search'" class="-header-space">
        <p class="ma-0 header-2">Found {{ searchResultArray.length }} items</p>
      </div>
      <div v-else-if="$route.name === 'approvedata'" class="-header-space">
        <p class="ma-0 header-2">
          Approve From User : {{ approveViewResult.owner }}
        </p>
      </div>
      <DirectoryHeader
        v-else-if="$route.name === 'share-with-link'"
        v-bind:currentPath="shareWithLinkResult.currentPath"
      />
      <DirectoryHeader v-else v-bind:currentPath="path" />
    </div>

    <DirectoryTable
      v-bind:directory="resolveDirectory"
      :isLoading="loadingData"
      :file_path="metadata.path"
      @sharedModal="sharedModal"
      @deleteModal="deleteModal"
      @s3KeyModal="s3KeyModal"
    />
    <DSMDialog
      :dialog="deleteDialog"
      :itemData="itemData"
      @deleteDialog="deleteModal"
    />
    <ShareDialog
      v-bind:results="accountResults"
      v-bind:itemData="itemData"
      v-bind:accessPermission="filePermission.is_public"
      v-bind:peopleShared="peopleList"
      :shareWithLink="filePermission.share_with_link"
      :ApproveStatus="filePermission.aprove_status"
      :statusCode="patchPermissionStatus"
      :shareCode="filePermission.share_code"
      @saveDialog="sharedModal"
      :dialog="shareDialog"
    />
    <S3KeyDialog
      :dialog="s3keyDialog"
      :S3key="S3keyValue"
      @saveDialog="s3KeyModal"
    />
  </div>
</template>

<script>
import DirectoryHeader from "./DirectoryHeader.vue";
import DirectoryTable from "./DirectoryTable.vue";
import {
  mapState,
  // mapGetters
} from "vuex";
import ShareDialog from "./ShareDialog.vue";
import DSMDialog from "./DSMDialog.vue";
import S3KeyDialog from "./S3KeyDialog.vue";

export default {
  components: {
    DirectoryHeader,
    DirectoryTable,
    ShareDialog,
    DSMDialog,
    S3KeyDialog,
  },
  props: {
    searchMode: {
      type: Boolean,
      default: false,
    },
    searchResultArray: {
      type: Array,
      default: () => [],
    },
    approveViewResult: {
      type: Object,
      default: () => {},
    },
    shareWithLinkResult: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    shareDialog: false,
    deleteDialog: false,
    s3keyDialog: false,
    itemData: {},
    S3keyValue: "",
  }),
  computed: {
    ...mapState({
      myDataset: (state) => state.myDataset,
      directoryRetive: (state) => state.directoryRetive,
      path: (state) => state.currentPath,
      filePermission: (state) => state.permissionFile,
      peopleList: (state) => state.peopleList,
      metadata: (state) => state.metadata,
      accountResults: (state) => state.accountResults,
      loadingData: (state) => state.loadingData,
      deleteStatus: (state) => state.deleteStatus,
      patchPermissionStatus: (state) => state.patchPermissionStatus,
    }),
    // ...mapGetters(["getPeopleList"]),
    resolveDirectory() {
      if (this.$route.name === "search") {
        return this.searchResultArray;
      } else if (this.$route.name === "approvedata") {
        if (this.$route.params.id) {
          return this.directoryRetive;
        } else {
          return this.approveViewResult.data;
        }
      } else if (this.$route.name === "share-with-link") {
        return this.shareWithLinkResult.data;
      } else {
        return this.directoryRetive;
      }
    },
  },
  methods: {
    sharedModal(val) {
      console.log("shareModal", val);
      if (val?.item_data) {
        this.itemData = val.item_data;
        let payload = {
          id:
            val.item_data.type === "dataset"
              ? val.item_data.dataset_id
              : val.item_data.id,
          type: val.item_data.type,
        };
        this.$store.dispatch("getfilePermissions", payload);
      }
      val.dialog === this.shareDialog
        ? (this.shareDialog = !this.shareDialog)
        : (this.shareDialog = val.dialog);
    },
    deleteModal(val) {
      console.log("deleteModal", val);
      if (val?.item_data) {
        this.itemData = val.item_data;
      }
      val.dialog === this.deleteDialog
        ? (this.deleteDialog = !this.deleteDialog)
        : (this.deleteDialog = val.dialog);
    },
    async s3KeyModal(val) {
      console.log("s3KeyModal", val);
      if (val?.item_data) {
        this.S3keyValue = `s3://${process.env.VUE_APP_BUCKETNAME}/${val.item_data}/*.parquet`;
      }
      val.dialog === this.s3keyDialog
        ? (this.s3keyDialog = !this.s3keyDialog)
        : (this.s3keyDialog = val.dialog);
    },
  },
  watch: {
    myDataset() {
      if (
        this.$route.name === "search" ||
        this.$route.name === "approvedata" ||
        this.$route.name === "share-with-link"
      ) {
        return;
      } else {
        let payloadData = {
          id: this.$route.params.id,
          path: this.$route.name,
        };
        this.$store.dispatch("getDirectory", payloadData);
      }
    },
    deleteStatus: {
      handler(val) {
        if (val == 204) {
          this.$store.commit("SET_LOADING", false);
          this.$store.dispatch("getDirectory", {
            id: this.$route.params.id,
            path: this.$route.name,
          });
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.header-2 {
  font-size: 1.125rem;
}
</style>
