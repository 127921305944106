<template>
  <v-card
    class="metadata-panel -loading d-flex justify-center align-center"
    v-if="isPanelLoading"
  >
    <v-progress-circular
      size="64"
      indeterminate
      color="primary"
    ></v-progress-circular>
  </v-card>
  <v-card class="metadata-panel" v-else>
    <div class="menu-tab">
      <v-tabs
        background-color="rgba(0, 0, 0, 0.06)"
        color="#000"
        center-active
        show-arrows
        slider-color="transparent"
      >
        <v-tab
          v-for="{ id, tab } in panelTab"
          :key="id"
          :value="id"
          @click="setTab(id)"
          style="text-transform: unset"
        >
          {{ tab }}
        </v-tab>
      </v-tabs>
    </div>
    <v-card class="flex-grow-1" elevation="0">
      <div
        v-if="isFrameLoading"
        class="-full-height d-flex justify-center align-center"
      >
        <v-progress-circular
          size="64"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div else class="-full-height -auto-overflow">
        <MetadataFrame
          v-if="this.tab === 1"
          :metaData="metadata"
          @patch-metadata="patchMetadata"
        />
        <ReadMeFrame v-if="this.tab === 2" :readMe="metadata.readme" />
        <ApproveStatusFrame
          v-if="this.tab === 3"
          :ActivityHistory="getActivityLogs"
        />
        <LineageFrame v-if="this.tab === 4" :lineageData="getLineageData" />
      </div>
    </v-card>
  </v-card>
</template>

<script>
import MetadataFrame from "./MetadataFrame.vue";
import ReadMeFrame from "./ReadMeFrame.vue";
import ApproveStatusFrame from "./ApproveStatusFrame.vue";
import LineageFrame from "./LineageFrame.vue";
import { mapGetters } from "vuex";

export default {
  name: "MetadataPanel",
  components: { MetadataFrame, ReadMeFrame, ApproveStatusFrame, LineageFrame },
  data: () => ({
    metadata: [],
    isFrameLoading: false,
    isPanelLoading: false,
    isDataset: true,
    tab: 1,
    panelTab: [],
  }),
  computed: {
    ...mapGetters(["getLineageData", "getActivityLogs"]),
  },
  watch: {
    metadata() {
      this.resolvePanelTab();
    },
    // getActivityLogs: {
    //   handler(val) {
    //     if (val > 0) {
    //       this.isFrameLoading = false;
    //     } else {
    //       this.isFrameLoading = false;
    //     }
    //   },
    //   deep: true,
    // },
    "$store.state.metadata"(value) {
      console.log("meta data change:", value);
      const fileMetadata =
        value.type === 2 ? { ...value, lineage: true } : value;
      this.metadata = fileMetadata;
      this.setIsPanelLoading();
    },
    tab: {
      handler(val) {
        // this.setIsPanelLoading();
        console.log("watch tab", val);
        switch (val) {
          case 1:
            this.setIsFrameLoading();
            break;
          case 2:
            this.setIsFrameLoading();
            break;
          case 3:
            this.setIsFrameLoading();
            console.log("This tab 3 here");
            this.$store.dispatch("callActivityLogs", {
              type: this.formatType(this.metadata.type),
              id: this.metadata.id,
            });
            break;
          case 4:
            this.setIsFrameLoading();

            break;
          default:
            break;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.$store.state.metadata.length === 0) {
      this.$store.dispatch("getMetadataByID", { type: 3, id: 1 });
    }
    this.metadata = this.$store.state.metadata;
  },
  methods: {
    setIsFrameLoading() {
      this.isFrameLoading = true;
      setTimeout(() => {
        this.isFrameLoading = false;
      }, 360);
    },
    setIsPanelLoading() {
      this.isPanelLoading = true;
      setTimeout(() => {
        this.isPanelLoading = false;
      }, 480);
    },
    setTab(val) {
      console.log("tab is", val);
      this.tab = val;
    },
    async resolvePanelTab() {
      this.isPanelLoading = true;
      this.panelTab = [];
      this.tab = 1;
      const tabArray = [
        {
          id: 0,
          tab: "Type",
          key: "type",
        },
        {
          id: 1,
          tab: "Metadata",
          key: "metadata",
        },
        {
          id: 2,
          tab: "Readme",
          key: "readme",
        },
        {
          id: 3,
          tab: "Activity",
          key: "activity",
        },
        {
          id: 4,
          tab: "Lineage",
          key: "lineage",
        },
      ];

      tabArray.forEach(({ id, tab, key }) => {
        const result = key in this.metadata;
        const isEmpty =
          this.metadata[key] === (null || undefined) ? true : false;

        if (result && id !== 0 && !isEmpty) {
          this.panelTab.push({ id, tab });
        }
      });
      setTimeout(() => {
        this.isPanelLoading = false;
      }, [180]);
    },
    patchMetadata(val) {
      const { id, type } = this.metadata;
      const { directoryId } = this.metadata.metadata;
      console.log("dir id", !directoryId, directoryId);
      if (!directoryId) {
        this.$store.dispatch("patchMetadata", { type, id, data: val });
      } else {
        this.$store.dispatch("patchMetadata", {
          type,
          id,
          directoryId,
          data: val,
        });
      }
      setTimeout(() => {
        let payloadData = {
          id: this.$route.params.id,
          path: this.$route.name,
        };
        this.$store.dispatch("getDirectory", payloadData);
      }, 1000);
    },
    formatType: (val) => {
      switch (val) {
        case 0:
          return "dataset";
        case 1:
          return "folder";
        case 2:
          return "file";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-card {
  overflow: auto;
}
.metadata-panel {
  width: 29.25em;

  // max-height: 100%;
  height: 82vh;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1297px) {
    width: 100%;
  }
  // @media screen and (max-width: 1180px) {
  //   height: 80vh;
  // }
}
.v-tab--active {
  background-color: #fff;
}

.panel-container {
  display: flex;
  flex-direction: column;
  width: 450px;
  border: 1px solid #dcdcdc;
  border-radius: 1.25rem;
}

.-full-height {
  height: 100% !important;
}
.-hide-overflow {
  overflow: hidden !important;
}
.-auto-overflow {
  overflow: auto !important;
}
</style>
