<template>
  <v-dialog
    v-model="toggleDialog"
    transition="dialog-bottom-transition"
    max-width="600"
    persistent
  >
    <template>
      <v-card>
        <v-toolbar
          color="rgb(2, 166, 231)"
          dark
          :style="{
            fontSize: `18px`,
          }"
        >
          <span> Create Database Connection </span>
          <v-icon @click="$emit('closeDialog', false)">mdi-close</v-icon>
        </v-toolbar>

        <v-card-text>
          <form>
            <v-text-field
              v-model="host"
              label="Host"
              required
              @input="$v.host.$touch()"
              @blur="$v.host.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="databaseName"
              :error-messages="databaseNameErrors"
              :counter="16"
              label="Database Name"
              required
              @input="$v.databaseName.$touch()"
              @blur="$v.databaseName.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="port"
              label="Port"
              required
              @input="$v.port.$touch()"
              @blur="$v.port.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="username"
              label="Username"
              required
              @input="$v.username.$touch()"
              @blur="$v.username.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="password"
              label="Password"
              required
              type="password"
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="tableName"
              label="Table Name"
              required
              @input="$v.tableName.$touch()"
              @blur="$v.tableName.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="sqlCommand"
              label="SQL Command"
              required
              @input="$v.sqlCommand.$touch()"
              @blur="$v.sqlCommand.$touch()"
            ></v-text-field>
          </form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <!-- <v-btn text @click="$emit('closeDialog', false)">Close</v-btn> -->
          <v-btn text @click="Save"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  props: {
    dialog: Boolean,
  },
  mixins: [validationMixin],
  validations: {
    databaseName: { required, maxLength: maxLength(16) },
    port: { required },
    host: { required },
    username: { required },
    password: { required },
    tableName: { required },
    sqlCommand: { required },
  },
  data: () => ({
    host: "",
    databaseName: "",
    port: "",
    username: "",
    password: "",
    tableName: "",
    sqlCommand: "",
  }),
  computed: {
    databaseNameErrors() {
      const errors = [];
      if (!this.$v.databaseName.$dirty) return errors;
      !this.$v.databaseName.maxLength &&
        errors.push("Database Name must be at most 16 characters long");
      !this.$v.databaseName.required &&
        errors.push("Database Name is required.");
      return errors;
    },
    toggleDialog() {
      console.log("go", this.dialog);
      return this.dialog;
    },
  },
  methods: {
    Save() {
      this.$v.$touch();
      let connectionData = {
        host: this.host,
        databaseName: this.databaseName,
        port: this.port,
        username: this.username,
        password: this.password,
        tableName: this.tableName,
        sqlCommand: this.sqlCommand,
      };
      this.$store.dispatch("createConnection", connectionData);
    },
    clear() {
      this.$v.$reset();
      this.host = "";
      this.databaseName = "";
      this.port = "";
      this.username = "";
      this.password = "";
      this.tableName = "";
      this.sqlCommand = "";
    },
  },
};
</script>

<style>
.v-toolbar__content {
  justify-content: space-between;
}
</style>
