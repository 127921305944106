<template>
  <v-dialog
    v-model="dialog"
    @click:outside="$emit('closeDialog', false)"
    width="500"
  >
    <v-card>
      <v-card-title class="text-h5"> This is your token </v-card-title>
      <v-card-text>
        <v-text-field readonly v-model="token" outlined dense>
          <template slot="append">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click="copyToken">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>Copy to clipboard</span>
            </v-tooltip>
          </template>
        </v-text-field>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text @click="$emit('closeDialog', false)">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    token: String,
  },
  methods: {
    copyToken() {
      navigator.clipboard.writeText(this.token);
    },
  },
};
</script>

<style></style>
