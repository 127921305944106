<template>
  <div class="display-profiling">
    <h3 class="bot-border">Profiling</h3>
    <div
      :id="item.column"
      class="data-profiling"
      v-for="(item, index) in Profiling"
      :key="index"
    >
      <div class="data-column" v-if="item.unique == true">
        <div class="column-type">
          <h3 :style="{ color: textColor }">{{ item.column }}</h3>
          <h3>{{ item.type }}</h3>
        </div>
        <div class="data-values">
          <h3 :style="{ color: textColor }">{{ item.unique_value }}</h3>
          <h3>Unique value</h3>
        </div>
      </div>

      <div class="data-hist" v-else-if="Object.keys(item).includes('hist')">
        <div class="column-type">
          <h3 :style="{ color: textColor }">{{ item.column }}</h3>
          <h3>{{ item.type }}</h3>
        </div>
        <div class="data-value">
          <Graph :id="index" :data="item.hist" :Color="Color" />
        </div>
      </div>

      <div
        class="data-column"
        v-else-if="item.type === 'category' && item.unique === false"
      >
        <div class="column-type">
          <h3>{{ item.column }}</h3>
          <h3>{{ item.type }}</h3>
        </div>
        <div
          class="percentage"
          v-for="(value, index) in item.word_count_percent"
          :key="index"
        >
          <div class="percent-key">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <h3 v-bind="attrs" v-on="on">{{ index }}</h3>
              </template>
              <span>{{ index }}</span>
            </v-tooltip>
          </div>
          <div class="percent-value">
            <h3 :style="{ color: textColor }">{{ value.toFixed(2) }} %</h3>
          </div>
        </div>
      </div>
      <div class="stat-column">
        <!-- <v-progress-linear class="valid-value" value="100" color="#40CEAE" /> -->

        <div class="data-stat">
          <div class="stat-title">
            <!-- <h3 class="text-space">
              Valid<v-sheet width="15" height="15" color="#40CEAE" />
            </h3> -->
            <!-- <div class="stat-group" v-if="Object.keys(item).includes('stat')">
              <h3 class="text-space">
                Mismatched<v-sheet width="15" height="15" color="#E38738" />
              </h3>
              <h3 class="text-space">
                Missing<v-sheet width="15" height="15" color="#D6544E" />
              </h3>
            </div> -->
            <h3>Unique</h3>
          </div>
          <div class="stat-value">
            <!-- <div class="stat-group" v-if="Object.keys(item).includes('stat')">
              <h3>{{ commaFormater(0) }}</h3>
              <h3>{{ commaFormater(0) }}</h3>
              <h3>{{ commaFormater(0) }}</h3>
            </div> -->
            <!-- <h3 v-if="item.type === 'category'">
              {{ commaFormater(item.valid ? itme.valid : 0) }}
            </h3> -->
            <h3>{{ commaFormater(item.unique_value) }}</h3>
          </div>
          <!-- <div class="stat-percentage">
            <h3>100 %</h3>
            <h3>{{ item.percent ? item.percent : NaN }} %</h3>
            <h3>{{ item.percent ? item.percent : NaN }} %</h3>
            <h3>{{ item.percent ? item.percent : NaN }} %</h3>
            <h3>{{ item.percent ? item.percent : NaN }} %</h3>
            <h3>{{ item.percent ? item.percent : NaN }} %</h3>
            <h3>{{ item.percent ? item.percent : NaN }} %</h3>
            <h3>{{ item.percent ? item.percent : NaN }} %</h3>
          </div> -->
        </div>
      </div>
      <div class="stat-column" v-if="Object.keys(item).includes('hist')">
        <div class="data-stat">
          <div class="stat-title">
            <h3>Min</h3>
            <h3>Max</h3>
            <h3>Mean</h3>
            <h3>Std</h3>
          </div>
          <div class="stat-value">
            <div class="stat-group" v-if="Object.keys(item).includes('stat')">
              <h3>{{ commaFormater(item.stat.min) }}</h3>
              <h3>{{ commaFormater(item.stat.max) }}</h3>
              <h3>{{ commaFormater(item.stat.mean) }}</h3>
              <h3>{{ commaFormater(item.stat.std) }}</h3>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="stat-column" v-if="Object.keys(item).includes('hist')">
        <div class="data-stat">
          <div class="stat-title">
            <h3>Quantiles</h3>
          </div>
          <div class="stat-value">
            <div class="stat-group" v-if="Object.keys(item).includes('stat')">
              <h3>{{ commaFormater(-1000) }}</h3>
              <h3>{{ commaFormater(-800) }}</h3>
              <h3>{{ commaFormater(-500) }}</h3>
              <h3>{{ commaFormater(-200) }}</h3>
              <h3>{{ commaFormater(0) }}</h3>
            </div>
          </div>
          <div class="stat-percentage">
            <h3>Min</h3>
            <h3>25 %</h3>
            <h3>50 %</h3>
            <h3>75 %</h3>
            <h3>Max</h3>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Graph from "./Graph.vue";
export default {
  props: {
    Profiling: {
      type: Array,
      default: () => [],
    },
    Example: {
      type: Array,
      default: () => [],
    },
    textColor: {
      type: String,
      default: "#02A6E7",
    },
  },
  data: () => ({
    headers: [],
    valueKeys: [],
  }),
  methods: {
    commaFormater: (val) =>
      val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
  },
  watch: {
    Example: {
      handler(val) {
        this.headers = Object.keys(val[0]).map((i) => {
          return {
            text: i,
            value: i,
          };
        });
        this.valueKeys = Object.keys(val[0]);
      },
      deep: true,
    },
  },
  components: { Graph },
};
</script>

<style lang="scss" scoped>
.data-profiling {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-grow: 1;
  min-height: 11.75rem;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
  .data-column,
  .data-hist {
    width: 25%;

    // min-height: 13.75em;
    .percentage {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .percent-key {
        width: 50%;
        font-weight: bold;
        h3 {
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
          text-overflow: ellipsis;
        }
      }
      .percent-value {
        width: 50%;
        font-weight: bold;
      }
    }
  }
  .data-column,
  .data-hist {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // padding: 0 0 1em 0;
    .data-value {
      line-height: 1.8em;
    }
  }
  .stat-column {
    width: 25%;
    display: flex;
    flex-direction: column;
    .valid-value {
      margin-bottom: 5px;
      height: 15px !important;
    }
    .stat-title,
    .stat-value {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }
    .data-stat {
      width: 100%;
      display: grid;
      z-index: 1;
      align-items: center;
      grid-template-columns: 1fr auto auto;
      .text-space {
        display: flex;
        gap: 10px;
        align-items: baseline;
      }
      .stat-percentage {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
      }
      @media print {
        justify-content: end;
      }
      .stat-value,
      .stat-percentage {
        display: flex;
        flex-direction: column;
        min-width: 5vw;
        text-align: end;
      }
    }
  }
}
</style>
