<template>
  <div :id="`graph${id}`" style="width: 100%"></div>
</template>

<script>
import embed from "vega-embed";
import spec from "@/assets/json/vegaSpec.json";

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      default: 0,
    },
    Color: {
      type: String,
      default: "#02A6E7",
    },
  },
  mounted() {
    console.log(`data${this.id}`, this.data);
    const newSpec = JSON.parse(JSON.stringify(spec));
    newSpec.data.values = this.data;
    newSpec.encoding.color.value = this.Color;
    console.log(`spec${this.id}`, newSpec);

    embed(`#graph${this.id}`, newSpec, {
      actions: false,
      renderer: "svg",
      padding: 0,
    });
  },
};
</script>

<style></style>
