<template>
  <div>
    <v-dialog v-model="loginDialog" width="500" persistent>
      <v-card class="d-flex flex-column align-center justify-center">
        <v-card-actions class="d-flex flex-column pt-6 px-6 pb-4">
          <p class="mb-0">Sorry, you can not perform this action.</p>
          <p class="mb-0">Please Log in and try again.</p>
        </v-card-actions>
        <v-card-actions>
          <v-btn
            class="white--text"
            color="#001E7D"
            elevation="2"
            @click="loginDialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-menu v-model="createMenu" rounded="lg" min-width="260">
      <template v-slot:activator="{ attrs }">
        <v-btn
          class="ma-2 white--text create-btn"
          v-bind="attrs"
          @click="toggleCreate()"
          color="#fff"
          height="55"
          rounded
        >
          <v-icon x-large left color="#fff"> mdi-plus </v-icon>
          New
        </v-btn>
      </template>
      <v-list>
        <template v-for="(item, index) in resolveCreateMenu">
          <v-divider
            v-if="index == 2 && resolveRequest(item.required_dataset)"
            :key="index"
          ></v-divider>
          <v-list-item
            v-if="resolveRequest(item.required_dataset)"
            :key="item.title"
            link
          >
            <v-list-item-content @click="toggleAction(item.value)">
              <v-list-item-title
                class="text-left"
                :style="{ fontSize: '18px' }"
                >{{ item.title }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>

    <div class="text-center">
      <v-dialog v-model="dialog" width="500" persistent>
        <v-card v-if="dialogType < 2">
          <v-form
            v-model="valid_form"
            ref="form"
            @submit.prevent="submitForm()"
          >
            <v-card-title class="d-flex justify-space-between">
              <span>
                Create new
                {{
                  dialogType === 0
                    ? "dataset"
                    : dialogType === 1
                    ? "Folder"
                    : ""
                }}
              </span>
              <v-icon @click="dialog = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text v-if="dialogType === 1"
              >Create folder under {{ resolveCurrentDir.text }}</v-card-text
            >
            <v-card-actions class="d-flex flex-column">
              <v-text-field
                id="name"
                class="--80-width"
                v-model="createData.name"
                type="text"
                :label="resolveNameLabel()"
                :rules="rules.name"
                :error-messages="errorNameMsg"
                dense
                outlined
              />
              <v-textarea
                v-if="dialogType === 1"
                class="--80-width"
                v-model="createData.description"
                :rules="rules.desc"
                :error-messages="errorNameMsg"
                label="Folder description"
                outlined
                no-resize
                counter
              />
              <!-- <v-text-field
                class="--80-width"
                v-if="dialogType === 0"
                v-model="createData.title"
                name="title"
                type="text"
                label="Display name"
                :rules="rules.title"
                dense
                outlined
              /> -->
              <v-combobox
                v-if="dialogType === 0"
                v-model="createData.tag"
                class="--80-width"
                eager
                placeholder="Please add tag"
                :rules="rules.tag"
                :items="tagItems"
                item-text="name"
                item-value="id"
                @change="onEnter"
                :search-input.sync="search"
                dense
                multiple
                outlined
                small-chips
                clearable
                hide-selected
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        No results matching "<strong>{{ search }}</strong
                        >".
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <!-- <template v-slot:selection="{ item, index }">
                  <span>{{ item.name }}</span>
                  <span v-if="index !== createData.tag.length - 1"
                    >,&nbsp;</span
                  >
                  <span v-else>&nbsp;</span>
                </template> -->
              </v-combobox>
              <!-- <span v-show="errorMsg || errorNameMsg" class="red--text">{{
                errorMsg || errorNameMsg
              }}</span> -->
            </v-card-actions>
            <v-divider></v-divider>
            <v-card-actions class="d-flex justify-end">
              <!-- TODO: loading when create -->
              <v-btn
                class="white--text"
                type="submit"
                :color="themeColor"
                :loading="submitLoading"
                elevation="2"
                :disabled="!valid_form"
              >
                Create
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
        <!-- Upload -->
        <v-card v-else>
          <v-form
            v-model="valid_form"
            ref="form"
            @submit.prevent="submitForm()"
          >
            <v-card-title class="d-flex justify-space-between">
              <span> Upload </span>
              <v-icon @click="dialog = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
              Upload {{ resolveUploadType }} to
              {{ resolveCurrentDir.text }}
            </v-card-text>
            <v-card-actions class="d-flex flex-column">
              <v-file-input
                v-if="dialogType === 2"
                ref="upload"
                v-model="upload"
                class="--80-width"
                multiple
                filled
                dense
                placeholder="Choose file"
                prepend-icon=""
                show-size
              >
                <template v-slot:prepend-inner>
                  <img src="@/assets/UploadFile.png" width="25" />
                </template>
              </v-file-input>
              <v-file-input
                v-else-if="dialogType === 3"
                v-model="upload"
                class="--80-width"
                prepend-icon="mdi-folder-upload"
                chips
                multiple
                show-size
                webkitdirectory
              >
                <!-- TODO:chip root folder -->
                <!-- <template v-slot:selection>
                  <v-chip v-if="upload.length > 0">a</v-chip>
                </template> -->
              </v-file-input>
            </v-card-actions>
            <v-divider></v-divider>
            <v-card-actions class="d-flex justify-end">
              <v-btn
                class="white--text"
                type="submit"
                :loading="submitLoading"
                :color="themeColor"
                elevation="2"
                :disabled="!valid_form"
              >
                Upload
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog v-model="CheckFile" width="500" persistent>
        <v-card>
          <v-card-title class="text-h5 lighten-2">
            Upload options
          </v-card-title>

          <v-card-text>
            "{{ fileUploadName }}" already exists in this directory. Do you want
            to replace the existing file or Keep both files ? Replacing the file
            Change sharing settings.
            <v-radio-group v-model="replaceFile">
              <v-radio
                :key="1"
                :value="true"
                label="Replace existing file"
              ></v-radio>
              <v-radio
                :key="2"
                :value="false"
                label="Keep both files"
              ></v-radio>
            </v-radio-group>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn :color="themeColor" text @click="cancelUpload">
              Cancel
            </v-btn>
            <v-btn :color="themeColor" @click="onUpload"> Upload </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Create-Btn",
  props: {
    themeColor: {
      type: String,
      default: "#02A6E7",
    },
  },
  // FIXME: State of components
  data: () => ({
    createMenu: false,
    submitLoading: false,
    isDataset: true,
    dialog: false,
    loginDialog: false,
    CheckFile: false,
    fileUploadName: "",
    valid_form: false,
    replaceFile: true,
    tagItems: [],
    search: null,
    createData: {
      name: "",
      title: "",
      description: "",
      tag: [],
    },
    rules: {
      name: [(v) => !!v || "This field is required"],
      title: [(v) => !!v || "This field is required"],
      tag: [(v) => v.length !== 0 || "Minimum 1 tag is required!"],
      desc: [
        (v) =>
          !!v ||
          ("This field is required!" && v.length <= 1024 && v.length != 0) ||
          "Description must be less than 1024 characters",
      ],
    },
    upload: [],
    Files: [],
    dialogType: 0,
    menuItems: [
      {
        value: 0,
        title: "Create Dataset",
        required_dataset: false,
      },
      // {
      //   value: 1,
      //   title: "Connect Database",
      //   required_dataset: false,
      // },
      {
        value: 2,
        title: "Create Folder",
        required_dataset: true,
      },
      {
        value: 3,
        title: "Upload Files",
        required_dataset: true,
      },
      // {
      //   value: 4,
      //   title: "Upload Folders",
      //   required_dataset: true,
      // },
    ],
    errorNameMsg: null,
    errorMsg: null,
    isSelecting: false,
  }),
  watch: {
    async dialog(val) {
      await this.$store.dispatch("getTag");
      if (val) {
        this.tagItems = await this.$store.state.tags;
      }
      this.createData = {
        name: "",
        title: "",
        description: "",
        tag: [],
      };
      this.upload = [];
      if (this.$refs["form"]) {
        this.$refs.form.resetValidation();
        this.errorNameMsg = null;
        this.errorMsg = null;
      }
    },
    checkFileStatus: {
      handler: function (val) {
        if (val == 302) {
          this.CheckFile = true;
        } else if (val == 200) {
          this.replaceFile = false;
          this.onUpload();
        }
      },
      immediate: true,
    },
    directoryRetive: {
      handler: function (val) {
        // let directoryData = {
        //   id: this.$route.params.di
        //   path: this.$route.name
        // }
        if (val) {
          this.submitLoading = false;
          this.dialog = false;
        }
      },
      immediate: true,
    },
    createStatus: {
      handler: function (val) {
        if (val.status == 201) {
          let payload = {
            id: this.$route.params.id,
            path: this.$route.name,
          };
          // this.$router.push({
          //   name: this.$route.name,
          //   params: { id: val.data.id },
          // });
          this.$store.dispatch("getDirectory", payload);

          this.submitLoading = false;
          this.dialog = false;
        } else {
          this.errorNameMsg = val.data.msg;
          this.submitLoading = false;
        }
      },
      immediate: true,
    },
    createData: {
      handler: function (val) {
        console.log("watch", val.tag);
        if (val.name || val.title) {
          console.log("what ", 1);
          this.errorNameMsg = null;
          this.valid_form = true;
        } else {
          console.log("what ");
          this.valid_form = false;
        }
      },
      immediate: true,
      deep: true,
    },
    upload: {
      handler: function (val) {
        if (val.length > 0) {
          this.errorMsg = null;
          this.valid_form = true;
        } else {
          this.valid_form = false;
        }
      },
      immediate: true,
      deep: true,
    },
    valid_form: {
      handler: function (val) {
        if (val) {
          this.errorMsg = null;
        } else {
          this.submitLoading = false;
        }
      },
      immediate: true,
    },
  },
  computed: {
    resolveCreateMenu() {
      if (this.currentPath.length > 1) return this.menuItems;
      else return this.menuItems.slice(0, 1);
    },
    resolveUploadType() {
      if (this.dialogType === 2) return "Files";
      return "Folder";
    },
    resolveCurrentDir() {
      return this.currentPath[this.currentPath.length - 1];
    },
    ...mapState({
      isLogin: (state) => state.isLogin,
      currentPath: (state) => state.currentPath,
      checkFileStatus: (state) => state.fileStatus,
      createTag: (state) => state.createTag,
      directoryRetive: (state) => state.directoryRetive,
      createStatus: (state) => state.createStatus,
    }),
  },
  methods: {
    async toggleAction(val) {
      console.log("receive:", val);
      switch (val) {
        case 0:
          this.dialogType = 0;
          this.dialog = true;
          break;
        case 1:
          // this.dialog = !this.dialog
          this.$emit("toggledialog", true);
          break;
        case 2:
          this.dialogType = 1;
          this.dialog = true;
          break;
        case 3:
          this.dialogType = 2;
          this.dialog = true;
          break;
        case 4:
          this.dialogType = 3;
          this.dialog = true;
          break;
      }
    },
    onEnter() {
      const newTag = this.createData.tag;
      const last = newTag.length - 1;
      const searchCheck = this.tagItems.find(
        (e) => e == newTag[last] || e.name == newTag[last]
      );
      // const checklist = this.tagItems.find((e) => e.name == newTag[last]);
      console.log("searchCheck :>> ", searchCheck);

      if (searchCheck === undefined) {
        let payload = {};
        newTag.map((i) => {
          return (payload = {
            name: i,
          });
        });
        this.$store.dispatch("createTag", payload);
      } else {
        // this.createData.tag = ;
      }
    },

    async submitForm() {
      console.log(this.$refs.form.validate());
      console.log("dialog type:", this.dialogType);
      console.log("send:", this.createData);
      console.log("send2:", this.tagItems);
      console.log("file", this.upload.length);
      this.errorNameMsg = null;
      this.errorMsg = null;

      let sendData = {
        name: "",
        title: "",
        tag: [],
      };

      if (this.dialogType < 2) {
        if (this.createData.title === "") {
          delete this.createData.title;
          this.createData.parent_dir = this.resolveCurrentDir.id;
        }
        if (this.createData.description === "") {
          delete this.createData.description;
        }
        if (this.createData.tag) {
          if (this.createData.tag.length === 0) {
            delete this.createData.tag;
          } else {
            const tagArray = this.createData.tag;
            const newTagArray = await tagArray
              .map((item) => {
                console.log("type of tagArr", typeof item);
                if (typeof item === "string") {
                  const { id } = this.tagItems.find((i) => i.name == item);
                  return id;
                } else {
                  return item.id;
                }
              })
              .filter((item) => item != undefined);
            console.log("newTagArray", newTagArray);
            console.log("newTagArray2", this.createTag);
            if (this.createTag?.id) {
              newTagArray.push(this.createTag.id);
            }

            sendData.tag = newTagArray;
          }
        }

        console.log("send2:", this.createData);
        if (this.createData.tag) {
          sendData.name = this.createData.name;
          sendData.title = this.createData.name;
          console.log("should dataset with:", sendData);
          // TODO: Create DataSet
          this.submitLoading = true;

          let resCreateDataset = await this.$store.dispatch("postCreate", {
            type: 0,
            data: sendData,
          });
          console.log("create get:", resCreateDataset);

          const { status, data } = resCreateDataset;
          if (status === 201) {
            this.dialog = false;
            this.submitLoading = false;
            // let newDatasetId = data.dataset.directory.id;
            setTimeout(() => {
              this.$router.push({
                name: "mydataset",
                params: {
                  id: 0,
                },
              });
            }, 480);
          } else if (status === 401) {
            this.submitLoading = false;
            this.errorMsg =
              "Access token is invalid.\nPlease log in and try again.";
            this.$store.commit("SET_LOGOUT");
            // setTimeout(() => {
            //   this.$router.go();
            // }, [800]);
          } else if (status === 400) {
            this.submitLoading = false;
            this.errorMsg = "Error: " + data.msg;
            this.errorNameMsg = data.msg;
            this.$refs.form.resetValidation();
          }
        } else {
          console.log("should folder with:", this.createData);
          this.submitLoading = true;

          const resCreateFolder = await this.$store.dispatch("postCreate", {
            type: 1,
            data: this.createData,
          });
          const { status, data } = resCreateFolder;
          console.log("create folder:", status, data);
          if (status === 201) {
            this.dialog = false;
            this.submitLoading = false;
          } else if (status === 401) {
            this.submitLoading = false;
            this.errorMsg =
              "Access token is invalid.\nPlease log in and try again.";
            this.$store.commit("SET_LOGOUT");

            // setTimeout(() => {
            //   this.$router.go();
            // }, [800]);
          } else if (status === 400) {
            this.errorMsg = "Error: " + data.msg;
            this.errorNameMsg = data.msg;
            this.$refs.form.resetValidation();
          }
        }
      } else if (this.upload.length > 0) {
        // TODO: Root_directory for set display folder upload
        const rootDirectory = this.upload[0].webkitRelativePath.split("/")[0];
        console.log("upload:", this.upload, "root path:", rootDirectory);
        let payload = {
          id: this.$route.params.id,
          name: this.upload[0].name,
        };

        this.fileUploadName = this.upload[0].name;
        this.Files = this.upload;
        this.$store.dispatch("checkFileExists", payload);
        // this.upload.forEach(async (item) => {
        //   let data = new FormData();
        //   data.append("file", await item);
        //   data.append("name", await item.name);
        //   data.append("directory", await this.resolveCurrentDir.id);
        //   data.append("is_use", true);
        //   data.append("size", await item.size);
        //   await this.$store.dispatch("postUploadFiles", data);
        // });
        // TODO: close modal when click Create
        // this.dialog = false;
      }
    },
    onUpload() {
      console.log("onUpload", this.Files);
      this.Files.forEach(async (item) => {
        let data = new FormData();
        data.append("name", await item.name);
        data.append("description", (await item.description) || "-");
        data.append("directory", await this.resolveCurrentDir.id);
        data.append("replace", this.replaceFile);
        let payload = {
          data: data,
          rawFile: await item,
        };
        this.submitLoading = true;
        this.dialog = false;
        await this.$store.dispatch("postUploadFiles", payload);
      });

      this.$store.dispatch("setFileStatus");
      this.CheckFile = false;
    },
    cancelUpload() {
      this.$store.dispatch("setFileStatus");
      this.CheckFile = false;
    },
    resolveRequest(val) {
      if (val && !this.isDataset) return false;
      return true;
    },
    resolveNameLabel() {
      return this.dialogType === 0 ? "Dataset name" : "Folder name";
    },
    // uploadFile() {
    //   console.log("upadl", this.$refs.upload);
    //   this.$refs.upload.click();
    // },
    toggleCreate() {
      if (this.isLogin) {
        this.createMenu = !this.createMenu;
      } else {
        this.loginDialog = true;
      }
    },
  },
};
</script>

<style lang="scss">
.create-btn {
  width: 100%;
  text-transform: unset;
}
.--80-width {
  width: 80%;
}
.v-text-field--filled {
  border-radius: 5px;
}
.v-input__append-outer {
  margin-top: 0 !important;
}
.v-text-field > .v-input__control > .v-input__slot:before {
  display: none;
}
</style>
