<template>
  <v-dialog
    v-model="dialog"
    @click:outside="$emit(`saveDialog`, { dialog: false })"
    max-width="600px"
  >
    <v-card class="mb-4">
      <v-card-title>
        <span class="text-h5">Get S3Key</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-text-field readonly v-model="S3key" outlined dense>
            <template slot="append">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="copyS3Key(S3key)">
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <span>Copy to clipboard</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    S3key: {
      type: String,
      default: "",
    },
  },
  methods: {
    copyS3Key: (val) => navigator.clipboard.writeText(val),
  },
};
</script>

<style></style>
