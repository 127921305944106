import styled from "vue-styled-components";

// import Component
import Navbar from "./Navbar/Navbar.vue";
import TreeView from "./DirectoryTree/DirectoryTree.vue";
import CreateBtn from "./DirectoryTree/CreateBtn.vue";
import DirectoryDisplay from "./FileExplorer/DirectoryDisplay.vue";
import MetadataPanel from "./MetadataPanel/MetadataPanel.vue";
import UploadQueue from "./UploadQueue/UploadQueue.vue";
import DisplayData from "./Datadictionary/DisplayData.vue";
import Dialog from "./DirectoryTree/Dialog.vue";
import NavigationDict from "./Datadictionary/NavigationDict.vue";
import AlertProgress from "./ProgressBar/AlertProgress.vue";

// default theme ETC.
let config = {
  color: {
    // primary: "#001E7D",
    primary: "#02A6E7",
    secondary: "#747474",
  },
  font: {
    title: "1.375rem",
    section: "1.125rem",
    body: "0.875rem",
  },
  background: "",
};

export const setConfig = (newConfig) => {
  console.log("config", newConfig);
  return (config = { ...config, newConfig });
};

// Style component Here !
export const Nav = styled(Navbar, config)`
display: flex;
  width: 100%;
  min-height: 5.5rem;
  border-bottom: 1px solid #ccc
  // margin-bottom: 0.625rem;
  background: ${(props) =>
    props.background ? props.background : config.color.secondary};
    .nav-items {
      .logoText {
        .c-primary {
          color: ${() => config.color.secondary};
        }
        .c-secondary {
          color: ${(props) =>
            props.color ? props.color : config.color.primary};
        }
      }
    }
  .nav-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 1.25rem;
    gap: 1.25rem;
    .v-btn--icon.v-size--default {
      width: 60px !important;
      height: 60px !important;
    }
    .v-btn--round {
      border-radius: 10%;
    }
  }
  @media screen and (max-width: 820px) {
    .navbar {
      display: flex;
      justify-content: space-between;
    }
  }
  @media print {
    display: none;
  }
`;

export const DirectoryTree = styled(TreeView, config)`
  // border: 0.063rem solid #ccc;
  // border-radius: 1.25rem;
  min-width: 18rem;
  flex: 1;
  overflow: auto;
  max-height: 82vh;
  .v-treeview-node__content {
    font-size: 0.875rem;
  }
  .v-treeview-node .theme--light.v-icon {
    color: ${config.color.secondary};
  }
`;

export const Container = styled.div`
  // padding: 0 0 16px 16px;
  // padding-left: 1em;
  // display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  // grid-template-rows: 1fr;
  // max-height: 100%;
  // width: 100%;
  // flex: 1;
  // overflow: hidden;
  // -webkit-box-pack: justify;
  // justify-content: space-between;
  @media screen and (max-width: 820px) {
    display: flex;
    width: 100%;
  }
`;

export const Sidebar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // gap: 1.25rem;
  @media print {
    display: none;
  }
`;

export const Newbtn = styled(CreateBtn, config)`
  width: 160px;
  min-height: 5.5rem;
  display: flex;
  align-items: center;
  .v-icon {
    background: ${(props) =>
      props.color ? props.color : config.color.primary};
    border-radius: 3.125rem;
  }
  .v-icon.v-icon--left {
    height: 2.5rem;
    width: 2.5rem;
  }
  .v-btn__content {
    font-size: 18px;
    color: #000;
    letter-spacing: 0;
  }
  .v-btn__content .v-icon--left {
    margin-left: -2.5rem;
    margin-right: 1.5rem;
  }
`;

export const FileExplorer = styled(DirectoryDisplay, config)`
  grid-column: span 4;
  grid-area: content;
  // border: 0.063rem solid #ccc;
  border-radius: 1.25rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .directory-header {
    // background-color: #001e7d;
    min-height: 5.5rem;
    display: flex;
    align-items: center;
    color: #000;
    border-bottom: 1px solid #ccc;
    border-radius: 1.25rem 1.25rem 0 0;
    .-header-space {
      padding: 6px 15px;
    }
    .v-breadcrumbs li {
      font-size: 1.125rem;
      color: ;
    }
    .v-breadcrumbs__item {
      color: #ccc;
    }
    .v-breadcrumbs__item--disabled {
      color: ${config.color.secondary};
    }
  }
  .directory-content {
    height: 100%;
    overflow: auto;
    display: flex;
    thead {
      tr th {
        font-size: 0.875rem !important;
      }
    }
    tbody {
      cursor: default;
      tr td {
        font-size: 0.875rem !important;
        .theme--light.v-icon {
          color: ${config.color.secondary} !important;
        }
      }
      .primary {
        background-color: rgba(0, 0, 0, 0.1) !important;
        border-color: none !important;
      }
    }
  }
  @media screen and (max-width: 820px) {
    width: 100%;
  }
`;

export const MetadataPanelstyled = styled(MetadataPanel, config)`
  // border: 0.063rem solid #ccc !important;
  grid-area: detail;
  border-radius: 0 !important;
  grid-column: span 2;
  box-shadow: none !important;
  border-left: 0.063rem solid #ccc !important;
  margin-top: 5.46rem;
  .v-slide-group__content {
    border-top: 1px solid #ccc !important;
  }
  .v-sheet.v-card {
    border-radius: none;
  }
  .v-tab {
    font-size: 1.125rem;
    letter-spacing: 0rem;
    font-weight: bold;
    padding: 0 10px;
  }
  .v-tabs.v-tabs--centered.theme--light {
    border-radius: 0;
    color: rgba(0, 0, 0, 0.34);
  }
`;

export const UploadQueueStyled = styled(UploadQueue, config)`
  position: absolute;
  bottom: 0;
  right: 16px;
`;

export const DataDictionaryDisplay = styled(DisplayData, config)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 90vh;
  grid-column: span 5;
  grid-area: content;
  .dict-table .v-data-table__wrapper {
    max-height: 529px;
  }
  .v-breadcrumbs__item {
    font-size: 14px;
    color: #ccc;
  }
  .print-btn {
    position: absolute;
    right: 0;
    display: flex;
  }

  .v-breadcrumbs__item--disabled {
    color: ${config.color.secondary}!important;
  }
  .form-html {
    font-size: 14px;
    h1 {
      font-size: 14px;
    }
  }
  .theme--light.v-data-table.v-data-table--fixed-header thead th {
    // color: #fff !important;

    background-color: #02a6e7;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    height: 2.5rem !important;
  }
  thead {
    background-color: #02a6e7;

    tr th {
      font-size: 14px !important;
      border: 1px solid #ccc;
    }
  }
  tbody {
    cursor: default;
    tr td {
      font-size: 14px !important;
      border: 1px solid #ccc;
      .v-text-field--filled {
        border-radius: 0;
      }
      .v-text-field > .v-input__control > .v-input__slot:before {
        display: none;
      }
    }
    .primary {
      background-color: rgba(0, 0, 0, 0.1) !important;
      border-color: none !important;
    }
  }
  @media screen and (max-width: 1000px) {
    width: unset;
    min-width: 35.3125em;
  }
  @media print {
    max-height: unset;
  }
`;

export const DialogComponent = styled(Dialog, config)``;

export const NavigateDict = styled(NavigationDict, config)`
  position: sticky;
  top: 0;
  width: 21.25em;
  height: 90vh;
  overflow-y: auto;
`;

export const DictContainer = styled.div`
  padding: 0 16px 16px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-rows: 1fr;
  max-height: 90vh
  width: 100%;
  // gap: 24px;
  flex: 1;
  overflow: auto;
  justify-content: space-between;
`;

export const UploadProgress = styled(AlertProgress, config)`
  position: fixed;
  width: 370px;
  right: 40px;
  bottom: 0;
`;

export const dataDictContainer = styled(`div`, config)`
  display: grid;
  grid-template-areas:
    "head head head head user"
    "side content content content content"
    "side content content content content";
  grid-template-columns: auto 1fr auto auto;
  grid-template-rows: auto auto auto 1fr;
  height: 100%;
  @media screen and (max-width: 1297px) {
    grid-template-areas:
      "head head head head user"
      "side content   detail"
      "side content   detail";
    grid-template-columns: auto auto auto auto;
  }
`;

export const driveContainer = styled(`div`, config)`
  display: grid;
  grid-template-areas:
    "head head head head user"
    "side content content detail detail"
    "side content content detail detail";
  grid-template-columns: auto 1fr auto auto;
  grid-template-rows: auto auto auto 1fr;
  height: 100%;
  @media screen and (max-width: 1297px) {
    grid-template-areas:
      "head head head head user"
      "side content   detail"
      "side content   detail";
    grid-template-columns: auto auto auto auto;
  }
`;
