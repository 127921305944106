export default {
  methods: {
    resolveUTCTime(time) {
      if (time === null || time === "" || time === undefined) return "-";

      const Time = new Date(time);
      const timeString =
        `${Time.getDate()}/${Time.getMonth() + 1}/${Time.getFullYear()} ` +
        `${Time.getHours()}`.padStart(2, "0") +
        `:` +
        `${Time.getMinutes()}`.padStart(2, "0");
      return timeString;
    },
  },
};
