<template>
  <div id="lineage"></div>
</template>

<script>
import G6 from "@antv/g6";
export default {
  props: {
    lineageData: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    drawLineage: () => {},
  },
  mounted() {
    const { lineageData } = this;
    const { nodes, edges } = lineageData;

    const data = {
      nodes: nodes.map((node) => ({
        id: node.id.toString(),
        label: node.name,
      })),
      edges: edges.map((edge) => ({
        source: edge.source.toString(),
        target: edge.target.toString(),
      })),
    };
    const width = document.getElementById("lineage").clientWidth;
    const graph = new G6.Graph({
      container: "lineage",
      width: width,
      height: 500,
      layout: {
        type: "dagre",
        nodesepFunc: (d) => {
          console.log(d);
          return 100;
        },
        ranksep: 20,
        controlPoints: true,
      },
      defaultNode: {
        type: "circle",
        labelCfg: {
          position: "bottom",
          offset: 15,
          style: {
            fill: "#000000",
            fontSize: 10,
          },
        },
      },
      defaultEdge: {
        type: "polyline",
        style: {
          radius: 20,
          offset: 45,
          startArrow: true,
          endArrow: false,
          lineWidth: 2,
          stroke: "#C2C8D5",
        },
      },
      nodeStateStyles: {
        selected: {
          stroke: "#d9d9d9",
          fill: "#5394ef",
        },
      },
      modes: {
        default: ["drag-canvas", "zoom-canvas"],
      },
      //   modes: {
      //     default: ["drag-canvas", "zoom-canvas", "click-select"],
      //   },
      fitView: true,
    });
    // add event click node antv
    graph.on("node:click", async (evt) => {
      const { item } = evt;
      const model = item.getModel();
      const { id } = model;
      const response = await this.$store.dispatch(
        "getFileRetrieve",
        parseInt(id)
      );
      const { data } = response;
      let directoryPayload = {
        id: data.directory,
        path: this.$route.name,
      };
      this.$store.dispatch("getDirectory", directoryPayload);
      this.$router.push({
        name: this.$route.name,
        params: {
          id: data.directory,
        },
      });
    });

    graph.data(data);
    graph.render();
  },
};
</script>

<style></style>
