<template>
    <div class="navbar">
        <div class="nav-items">
            <img
                v-if="this.$route.name != `datadictionary`"
                class="logo"
                :src="Logo"
                alt="logo"
                @click="goHome"
                width="50"
            />
            <img
                v-else
                class="logo"
                src="@/assets/parquetLogo.png"
                alt="logo"
                @click="goHome"
                width="50"
            />
            <div
                class="logoText"
                v-if="this.$route.name != `datadictionary`"
                @click="goHome"
            >
                <h1 class="c-primary">{{ Logotext1 }}</h1>
                <h1 class="c-secondary">{{ Logotext2 }}</h1>
            </div>
            <div class="docs-title" v-else>
                <h1>{{ FileName }}</h1>

                <h3 class="menu-bar">File</h3>
            </div>
        </div>
        <div
            v-if="this.$route.name != `datadictionary`"
            class="nav-center-items"
        >
            <SearchBox />
            <div class="nav-menu">
                <!-- <Gridmenu /> -->
                <!-- <User :user="profile" @getToken="getToken" /> -->
                <!-- <DialogToken @closeDialog="getToken" :token="token" :dialog="dialog" /> -->
            </div>
        </div>
    </div>
</template>

<script>
// import User from "./User.vue";
// import Gridmenu from "./Gridmenu.vue";
import SearchBox from "./SearchBox.vue";
// import { mapState } from "vuex";
import DataplatLogo from "@/assets/DataplatLogo-wot.png";
// import ParquetLogo from "@/assets/parquetLogo.png";
// import DialogToken from "./DialogToken.vue";
export default {
    props: {
        FileName: {
            type: String,
            default: "Title of File",
        },
        Logo: {
            type: String,
            default: DataplatLogo,
            // this.$route.name != `datadictionary` ? DataplatLogo : ParquetLogo,
        },
    },
    components: {
        // User,
        SearchBox,
        // DialogToken,
        // Gridmenu,
    },
    data: () => ({
        dialog: false,
        Logotext1: process.env.VUE_APP_LOGOTEXT_1,
        Logotext2: process.env.VUE_APP_LOGOTEXT_2,
        // token: localStorage.getItem("token"),
    }),
    mounted() {
        console.log("route", this.$route);
        // this.$store.dispatch("getUserProfile");
    },
    // computed: {
    //   ...mapState({
    //     profile: (state) => state.user,
    //   }),
    // },
    methods: {
        goHome() {
            this.$router.push("/");
        },
        // getToken(val) {
        //   console.log("getTOken", val);
        //   val === this.dialog ? (this.dialog = !this.dialog) : (this.dialog = val);
        // },
    },
};
</script>

<style lang="scss" scoped>
.navbar {
    /* display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem;
  background-color: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.1); */
    padding-left: -0.75em;
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    // grid-template-rows: auto;
    // gap: 24px;
    .nav-items {
        grid-column-start: 1;
        padding: 0.625rem;
        display: flex;
        align-items: center;
        min-width: 287px;
        gap: 10px;
        .logoText {
            display: flex;
        }
        .docs-title {
            display: flex;
            flex-direction: column;
        }
    }
    .nav-center-items {
        // grid-column: span 2;
        // height: 40px;
        // align-self: center;
        // z-index: 10;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .logo {
        cursor: pointer;
    }
}
</style>
>
