<template>
  <v-alert v-model="visible" dense :color="'#fff'" elevation="19">
    <!-- vuetify alert icon slot -->
    <!-- <template #prepend>
      <v-icon v-if="status === `success`" size="30" color="success"
        >mdi-check-circle-outline</v-icon
      >
      
    </template> -->
    <!-- <template #default>
      File Name
     
    </template> -->
    {{ FileName }}
    <v-progress-linear
      absolute
      bottom
      height="5"
      :value="value"
      :color="value === 100 ? 'success' : 'primary'"
    >
    </v-progress-linear>
  </v-alert>
</template>

<script>
export default {
  props: {
    FileName: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: 0,
    },
    status: String,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(val) {
      if (val === 100) {
        setTimeout(() => {
          let payloadData = {
            id: this.$route.params.id,
            path: this.$route.name,
          };
          this.$store.dispatch("getDirectory", payloadData);
          this.visible = false;
        }, 3000);
      }
    },
  },
};
</script>

<style></style>
