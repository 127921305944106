<template>
  <v-breadcrumbs class="-header-space" light :items="currentPath">
    <template v-slot:divider>
      <v-icon size="16" light>mdi-play</v-icon>
    </template>
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :href="item.href"
        :disabled="item.disabled"
        @click="onClick(item)"
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  props: {
    currentPath: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onClick(item) {
      console.log("clickPath", item);
      if (item) {
        switch (item.type) {
          case "folder":
            this.$store.dispatch("getMetadataByID", {
              type: 1,
              id: item.id,
            });
            break;
          case "dataset":
            // this.$store.dispatch("getMetadataByID", {
            //   type: 0,
            //   id: item.id,
            // });
            break;
          default:
            break;
        }

        // if (item.id === 0) {
        //   this.$store.dispatch("getMydataset");
        // }
        let payloadData = {
          id: item.id,
          path: this.$route.name,
        };
        this.$store.dispatch("getDirectory", payloadData);
        this.$router.push({ name: this.$route.name, params: { id: item.id } });
      }
    },
  },
};
</script>

<style lang="scss">
.v-breadcrumbs__item {
  cursor: pointer !important;
}
</style>
