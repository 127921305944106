<template>
  <div class="datadicframe px-8 py-4">
    <div class="print-btn">
      <!-- <v-checkbox
        v-model="printCheckbox"
        dense
        hide-details
        label="DataDictionary"
        value="DataDictionary"
      ></v-checkbox> -->

      <!-- <v-checkbox
        v-model="printCheckbox"
        dense
        hide-details
        label="Profiling"
        value="Profiling"
      ></v-checkbox> -->

      <v-btn class="print-assign" @click="Print" text>
        <v-icon>mdi-printer</v-icon>
        Print
      </v-btn>
    </div>
    <div id="Data Dictionary">
      <h3>File id : {{ fileId }}</h3>
      <h3>Title : {{ fileName }}</h3>
      <h3 class="w-50">Description : {{ description }}</h3>
      <v-btn
        outlined
        v-if="pipline != null"
        ripple
        @click="pipelineView(pipline)"
      >
        Run id : {{ pipline.run_pipeline_id }}
      </v-btn>
      <DataHeader v-bind:currentPath="filePath" />
      <DataTable
        v-bind:TableTitle="title"
        v-bind:datadic="dataDict"
        v-bind:dataType="dataType"
      />
    </div>

    <div id="Data Example">
      <h3 class="bot-border no-underline for-web">Data Example</h3>

      <DataExample :Example="profiling.example" :dataDict="dataDict" />
    </div>

    <div id="Profiling" class="for-web">
      <Profiling
        :Profiling="profiling.profile"
        :textColor="configTheme.primaryColor"
      />
    </div>
  </div>
</template>

<script>
import DataTable from "./DataTable.vue";
import DataHeader from "./DataHeader.vue";
import { mapState } from "vuex";
import Profiling from "./Profiling.vue";
import DataExample from "./DataExample.vue";
// import DsmBtn from "../Button/dsm-btn.vue";
// import TableForm from "./TableForm.vue";
export default {
  components: {
    DataTable,
    DataHeader,
    Profiling,
    DataExample,
    // DsmBtn
  },
  props: {
    fileId: String,
    fileName: {
      type: String,
      default: "Title of File",
    },
    description: {
      type: String,
      default: "Description of File",
    },
    filePath: Array,
    context: Object,
  },
  data: () => ({
    title: "Data Dictionary",
    printCheckbox: ["DataDictionary"],
    pipeline_log: null,
  }),
  computed: {
    ...mapState({
      dataDict: (state) => state.dataDictionary,
      profiling: (state) => state.profiling,
      dataType: (state) => state.data_type,
      configTheme: (state) => state.configTheme,
    }),
    pipline: {
      get() {
        return this.context?.pipeline_log || null;
      },
      set(val) {
        console.log("val", val);
        // this.context.pipeline_log = val;
      },
    },
  },
  mounted() {
    console.log("Route", this.$route);
    this.path = this.$route.path.split("/")[3];
    this.$store.dispatch("getDataType");
    // if (this.$store.state.profiling.example?.length) {
    //     // Fetch data dic if have profiling
    //     this.$store.dispatch("getDatadictionary", this.$route.params.id);
    // }
    // this.$store.dispatch("getProfiling", this.$route.params.id);
    console.log("datadic", this.dataDict);
  },
  methods: {
    clickInfo() {
      const routeData = this.$router.resolve({
        path: `/DataDictionary/${this.$route.params.id}/info`,
      });
      window.open(routeData.href, "_blank");
    },
    Print() {
      window.print();
    },
    pipelineView(val) {
      console.log("pipeline_log", val);
      window.open(val.run_pipeline_link, "_blank");
    },
  },
  watch: {
    printCheckbox(val) {
      val.includes("DataDictionary")
        ? document
            .getElementById("Datadictionary")
            .classList.remove("print-notdisplay")
        : document
            .getElementById("Datadictionary")
            .classList.add("print-notdisplay");

      // val.includes("Profiling")
      //   ? document
      //       .getElementById("Profiling")
      //       .classList.remove("print-notdisplay")
      //   : document
      //       .getElementById("Profiling")
      //       .classList.add("print-notdisplay");
    },
    // context: {
    //   handler(val) {
    //     this.pipeline_log = val?.pipeline_log || null;
    //     console.log("pipelog", val);
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
};
</script>

<style scoped lang="scss">
.datadicframe {
  position: relative;
  display: flex;
  gap: 10px;
  overflow: auto;
}

.print-assign {
  height: unset !important;
}
</style>
