<template>
  <div v-click-outside="closeSearchModel" class="search-container">
    <v-text-field
      v-model="search"
      placeholder="Search"
      append-icon="mdi-magnify"
      clearable
      hide-details
      filled
      :loading="isLoading"
      dense
      @click="openSearchModel()"
      @keypress.enter="redirectToSearch()"
    ></v-text-field>
    <v-card v-show="model" class="mt-2 p-fixed">
      <!-- <div v-if="isLoading" class="d-flex justify-center">
				<v-progress-circular
					class="ma-4 mx-auto"
					indeterminate
					color="primary"
				></v-progress-circular>
			</div> -->
      <v-list dense>
        <v-list-item-group
          v-if="searchResult.length === 0 && search.length > 0"
          color="#001E7D"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Not found "{{ search }}"</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-list-item-group v-else color="#001E7D">
          <v-list-item
            class="search-result-list"
            v-for="(item, i) in searchResult"
            :key="i"
            @click="onClickSearch(item)"
          >
            <v-list-item-content class="flex-none mr-4">
              <img
                v-if="item.name.split('.')[1] === 'parquet'"
                src="@/assets/parquet.svg"
                width="20"
                height="20"
              />

              <img
                v-else-if="item.type === 1 && item.file_type === null"
                src="@/assets/folder.svg"
                width="20"
                height="20"
              />
              <img
                v-else-if="item.type === 0 && item.file_type === null"
                src="@/assets/data.svg"
                width="20"
                height="20"
              />
              <v-icon v-else> mdi-file </v-icon>
              <!-- <v-icon
								v-text="resolveIcon(item.type, item.file_type)"
							></v-icon> -->
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title
                v-html="highlight(item.name)"
              ></v-list-item-title>

              <v-list-item-subtitle v-text="item.owner"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content class="flex-none self-start">
              <v-list-item-subtitle>{{
                resolveUTCTime(item.last_modified)
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
  <!-- <v-autocomplete
		v-model="model"
		:items="items"
		:loading="isLoading"
		hide-no-data
		solo-inverted
		rounded
		light
		prepend-inner-icon="mdi-magnify"
	></v-autocomplete> -->
</template>

<script>
import TimeConvertMixins from "../../mixins/TimeConvertMixins";
import SearchConvertMixins from "../../mixins/SearchConvertMixins";
import sample from "../../mock/SearchResult.json";

export default {
  name: "SearchBox",
  mixins: [TimeConvertMixins, SearchConvertMixins],
  data: () => ({
    isLoading: false,
    model: false,
    awaitingSearch: false,
    search: "",
    searchResult: [],
    selectedSearch: null,
    sampleResult: sample,
    limitColumns: 7,
  }),
  watch: {
    search: {
      handler(val) {
        if (val.length > 0) {
          this.searchAction(val);
          this.isLoading = true;
          this.awaitingSearch = true;
        } else {
          this.searchResult = [];
          this.isLoading = false;
          this.awaitingSearch = false;
        }
      },
      // if (this.search === null) {
      //   this.search = "";
      //   this.isLoading = false;
      //   this.awaitingSearch = false;
      //   this.searchResult = [];
      // } else {
      //   // if (!this.awaitingSearch) {
      //   //   setTimeout(async () => {
      //   //     this.searchResult = await this.searchAction(this.search);
      //   //     this.isLoading = false;
      //   //     this.awaitingSearch = false;
      //   //   }, 180);
      //   // }
      //   this.searchAction(this.search);
      //   this.isLoading = true;
      //   this.awaitingSearch = true;
      // }
      // console.log('watch search:', val)
    },
    searchResult: {
      handler(val) {
        if (val.length > 0) {
          this.isLoading = false;
          this.awaitingSearch = false;
        }
        // console.log('watch searchResult:', val)
      },
      immediate: true,
    },
    // $route(to, from) {
    //   const oldQ = from.query.q;
    //   const newQ = to.query.q;
    //   console.log("reloa router:", from.query, to.query, newQ != "");
    //   if (newQ == "") {
    //     this.$router.push("/");
    //   }
    //   if (oldQ != newQ) {
    //     console.log("reload");
    //     window.location.reload();
    //     // this.$router.push({ path: `/search?q=${newQ}` });
    //   }
    // },
  },
  mounted() {
    const searchKeyword = this.$route.query.q;
    if (searchKeyword) {
      this.search = searchKeyword;
    }
  },
  methods: {
    closeSearchModel() {
      this.model = false;
    },
    openSearchModel() {
      this.model = true;
    },
    async searchAction(val) {
      console.log("search action");
      const result = [];
      const searchResult = await this.$store.dispatch("getSearch", {
        keyword: val,
      });
      const { status, data } = searchResult;
      if (status === 200) {
        console.log("search result:", data);
        const resolvedData = await this.mapSearchResult(data);
        // console.log("resolvedData", resolvedData);
        resolvedData.map((item) => {
          // const { name } = item;
          if (result.length < this.limitColumns) {
            result.push(item);
          }
        });
        console.log("display result:", result);
      }
      this.searchResult = result;
      // return result;
    },
    highlight(string) {
      if (!this.search) {
        return string;
      }
      return string.replace(new RegExp(this.search, "gi"), (match) => {
        return '<span class="highlightText">' + match + "</span>";
      });
    },
    resolveIcon(a, b) {
      if (a < 2) {
        switch (a) {
          case 0:
            return "mdi-database";
          case 1:
            return "mdi-folder";
        }
      } else {
        switch (b) {
          case ".csv":
            return "mdi-file-table";
          case ".xlsx":
            return "mdi-file-excel";
          default:
            return "mdi-file";
        }
      }
    },

    onClickSearch(val) {
      console.log("click search item:", val);
      const toDataDict = this.$router.resolve({
        name: "datadictionary",
        params: { id: val.id },
      });
      switch (val.type) {
        case 0:
          this.$router.push({
            name: val.from,
            params: {
              id: val.id,
            },
          });
          this.closeSearchModel();
          break;
        case 1:
          this.$router.push({
            name: val.from,
            params: {
              id: val.id,
            },
          });
          this.closeSearchModel();
          break;
        case 2:
          localStorage.setItem("fileData", JSON.stringify(val));
          val.name.split(".")[1] === "parquet"
            ? window.open(toDataDict.href, "_blank")
            : null;
          this.closeSearchModel();
          break;
        default:
          // this.$router.push({
          //   path: `/file/${val.id}`,
          //   query: { q: this.search },
          // });

          break;
      }
    },
    redirectToSearch() {
      console.log("redirect");
      this.$router.push({ name: "search", query: { q: this.search } });
      this.closeSearchModel();
      // this.$router.go();
    },
  },
};
</script>

<style lang="scss" scoped>
.search-container {
  min-width: 35.5em;
  @media screen and (max-width: 960px) {
    min-width: 100%;
  }
}
.v-text-field--filled {
  border-radius: 5px;
}

.search-result-list {
  &:hover {
    background-color: rgb(79, 193, 238, 0.2) !important;

    * {
      color: #001e7d !important;
    }
  }
}
.flex-none {
  flex: none;

  &.self-start {
    align-self: flex-start;
  }
}
.highlightText {
  background: rgb(79, 193, 238, 0.2);
}
</style>
