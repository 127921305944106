<template>
  <v-dialog v-model="dialog" persistent max-width="300px">
    <v-card class="mb-4">
      <v-card-title>
        <span class="text-h5">Permanent Deleted ?</span>
      </v-card-title>
      <v-card-text>
        "{{ itemData.name }}" will be deleted forever.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="blue darken-1"
          text
          @click="$emit(`deleteDialog`, { dialog: false })"
        >
          Cancel
        </v-btn>
        <v-btn color="blue darken-1" dark @click="onDelete(itemData)">
          Delete
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    itemData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onDelete(item) {
      console.log("onDelete", item);
      //   this.$emit("delete", this.itemData);
      this.$store.commit("SET_DELETESTATUS", null);
      this.$store.dispatch("deleteItem", item);
      this.$emit(`deleteDialog`, { dialog: false });
    },
  },
};
</script>

<style lang="scss">
.v-dialog {
  box-shadow: unset;
}
</style>
