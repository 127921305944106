<template>
  <v-progress-circular
    v-if="isLoading"
    size="64"
    indeterminate
    color="primary"
  ></v-progress-circular>
  <div
    v-else
    class="metadata-frame px-4 py-6"
    style="font-size: 0.875rem; color: rgba(0, 0, 0, 0.87)"
  >
    <div class="d-flex flex-column">
      <div class="d-flex flex-row justify-space-between">
        <h3>Description</h3>
        <div v-if="resolveDataState">
          <v-icon
            v-if="isDescDisabled"
            title="edit description"
            class="-icon"
            @click="toggleEditMode('desc')"
          >
            mdi-rename-box
          </v-icon>
          <v-icon
            v-else
            title="save edit description"
            class="-icon"
            @click="toggleEditMode('desc')"
          >
            mdi-content-save
          </v-icon>
        </div>
      </div>
      <v-textarea
        class="mt-4 desc-input"
        v-model="data.description"
        :readonly="isDescDisabled"
        :placeholder="descPlaceholder"
        rows="8"
        spellcheck
        :rules="[
          (v) =>
            (v || '').length <= 1024 ||
            'Description must be 1024 characters or less',
        ]"
        outlined
        no-resize
        counter
      />
    </div>
    <v-divider class="my-3" />
    <div class="">
      <label>Meta data</label>
      <div class="section-metadata mt-4">
        <label class="label">Name :</label>
        <div class="detail -edit -max-content">
          <span v-if="isNameDisabled" class="detail">
            {{ data.name }}
            <!-- {{ data.name }}<span v-if="data.id_name">({{ data.id_name }})</span> -->
          </span>
          <v-text-field
            v-else
            class="detail-input py-0 my-0"
            v-model="data.name"
            :rules="rulesName"
            outlined
          />
        </div>
        <div class="flex-end" v-if="resolveDataState">
          <v-icon
            v-if="isNameDisabled"
            title="edit name"
            class="-icon"
            @click="toggleEditMode('name')"
          >
            mdi-rename-box
          </v-icon>
          <v-icon
            v-else
            title="save edit name"
            class="-icon"
            @click="toggleEditMode('name')"
          >
            mdi-content-save
          </v-icon>
        </div>
        <span class="label">Owner :</span>
        <span class="detail">{{ data.owner }}</span>
        <span class="label">Owner email :</span>
        <span class="detail">{{ data.email }}</span>
        <span class="label">Created :</span>
        <span class="detail">{{ data.create_at }}</span>
        <span class="label">Last Modified :</span>
        <span class="detail">{{ data.last_modified }}</span>
        <span v-if="isFile" class="label">MachineSize :</span>
        <span v-if="isFile" class="detail"
          >{{ data.size.toLocaleString() }} B</span
        >
        <span v-if="isFile" class="label">HumanSize :</span>
        <span v-if="isFile" class="detail s-end">{{ data.human_size }} </span>
        <span v-if="isFile" class="label">Type :</span>
        <span v-else-if="!isFolder" class="label">Tag :</span>
        <span v-if="isFile" class="detail">{{ data.type }}</span>
        <div v-else-if="!isFolder" class="detail -edit -max-content">
          <div v-if="isTagDisabled" class="detail">
            <span v-for="(item, index) in data.tag" :key="item.id">
              {{ item.name }}
              <span v-if="index < data.tag.length - 1">&#44;</span>
            </span>
          </div>
          <v-combobox
            v-else
            v-model="data.tag"
            class="detail -max-content"
            eager
            placeholder="Please add tag"
            :rules="rulesTag"
            :items="tagItems"
            item-text="name"
            item-value="id"
            return-object
            @keyup.enter="onEnter()"
            :search-input.sync="search"
            dense
            multiple
            outlined
            hide-selected
            persistent-hint
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    No results matching "<strong>{{ search }}</strong
                    >".
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item, index }">
              <span>{{ item.name }}</span>
              <span v-if="index !== data.tag.length - 1">,&nbsp;</span>
              <span v-else>&nbsp;</span>
            </template>
          </v-combobox>
        </div>
        <div class="flex-end" v-if="resolveDataState">
          <v-icon
            v-if="isTagDisabled && !isFolder && !isFile"
            title="edit tag"
            class="-icon"
            @click="toggleEditMode('tag')"
          >
            mdi-rename-box
          </v-icon>
          <v-icon
            v-else-if="!isFolder && !isFile"
            title="save edit tag"
            class="-icon"
            @click="toggleEditMode('tag')"
          >
            mdi-content-save
          </v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TimeConvertMixins from "../../mixins/TimeConvertMixins";

export default {
  name: "MetadataFrame",
  mixins: [TimeConvertMixins],
  props: {
    metaData: {
      type: [Object, Array],
      required: true,
    },
  },
  data: () => ({
    isOwner: true,
    isFile: false,
    isFolder: false,
    isLoading: false,
    isDescDisabled: true,
    isNameDisabled: true,
    isTagDisabled: true,
    descPlaceholder: "Please select item to see metadata",
    data: [],
    search: null,
    tagItems: [],
    rulesTag: [(v) => v.length !== 0 || "Minimum 1 tag is required!"],
    rulesName: [(v) => v.length !== 0 || "Name is required!"],
  }),

  mounted() {
    this.isLoading = true;
    this.GetMetadata();
    this.tagItems = this.$store.state.tags;
    this.isLoading = false;
  },

  watch: {
    // data: {
    //   handler(oldVal, newVal) {
    //     console.log('deep:', oldVal, '\nnew:', newVal)
    //   },
    //   deep: true
    // },
  },

  computed: {
    resolveDataState() {
      const { type } = this.metaData;
      console.log("type resolveData", type);
      if (type !== "default" && this.isOwner) {
        return true;
      }
      return false;
    },
  },

  methods: {
    onEnter() {
      const newTag = this.data.tag;
      const last = newTag.length - 1;
      const searchCheck = this.tagItems.find((e) => e == newTag[last]);
      if (searchCheck === undefined) {
        this.data.tag.pop();
      }
    },
    toggleEditMode(type) {
      const oldMetadata = this.$store.state.metadata;
      if (type === "desc") {
        console.log("desc same:");
        if (
          !this.isDescDisabled &&
          this.data.description !== oldMetadata.metadata.description
        ) {
          console.log("update desc:", this.data.description);
          const patch = { description: this.data.description };
          this.$emit("patch-metadata", patch);
        }
        this.isDescDisabled = !this.isDescDisabled;
      } else if (type === "name") {
        if (this.data.name.length > 0) {
          if (
            !this.isNameDisabled &&
            this.data.name !== oldMetadata.metadata.name
          ) {
            console.log("update name:", this.$store.state.metadata.type);
            const metadataType = this.$store.state.metadata.type;
            var patch = {};
            if (metadataType === 0) {
              patch.name = `${this.data.name}`;
              patch.title = `${this.data.name}`;
            } else {
              patch.name = `${this.data.name}`;
            }
            this.$emit("patch-metadata", patch);
          }
          this.isNameDisabled = !this.isNameDisabled;
        }
      } else if (type === "tag") {
        if (this.data.tag.length > 0) {
          if (
            !this.isTagDisabled &&
            this.data.tag !== oldMetadata.metadata.tag
          ) {
            console.log(
              "update tag:",
              this.data.tag !== oldMetadata.metadata.tag,
              "|",
              this.data.tag
            );
            const patch = { tag: this.data.tag.map((item) => item.id) };
            this.$emit("patch-metadata", patch);
          }
          this.isTagDisabled = !this.isTagDisabled;
        }
      }
    },
    resolveFileName(name) {
      return name;
      // return name.split(".")[0];
    },
    async GetMetadata() {
      console.log("receive data:", this.metaData);
      const { type, metadata } = await this.metaData;
      switch (type) {
        case 0: {
          this.data = {
            description: metadata.description || null,
            id_name: metadata.name || "",
            name: metadata.title || "",
            owner: metadata.owner?.user || "",
            email: metadata.owner?.email || "",
            create_at: this.resolveUTCTime(metadata.create_at) || "",
            last_modified: this.resolveUTCTime(metadata.last_modified) || "",
            tag: metadata.tag,
          };
          break;
        }
        case 1: {
          this.isFolder = true;
          this.data = {
            description: metadata.description || null,
            name: metadata.name || "",
            owner: metadata.owner?.user || "",
            email: metadata.owner?.email || "",
            create_at: this.resolveUTCTime(metadata.create_at) || "",
            last_modified: this.resolveUTCTime(metadata.last_modified) || "",
          };
          break;
        }
        case 2: {
          this.isFile = true;
          this.data = {
            description: metadata.description || null,
            name: this.resolveFileName(metadata.name) || "",
            owner: metadata.owner?.user || "",
            email: metadata.owner?.email || "",
            create_at: this.resolveUTCTime(metadata.create_at) || "",
            last_modified: this.resolveUTCTime(metadata.last_modified) || "",
            size: metadata.size || "-",
            human_size: metadata.human_size || "-",
            type: metadata.type.name || "-",
          };
          break;
        }
        default: {
          console.log("is de");
          this.data = {
            description: metadata.description || null,
            name: metadata.name || "",
            owner: metadata.owner?.user || "",
            email: metadata.owner?.email || "",
            create_at: this.resolveUTCTime(metadata.create_at) || "",
            last_modified: this.resolveUTCTime(metadata.last_modified) || "",
            size: metadata.size || "-",
            human_size: metadata.human_size || "-",
            type: metadata.type.name || "-",
          };
          break;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-list-item__mask {
  color: #000 !important;
  background-color: #dddddd !important;
}
.v-select--is-menu-active {
  border: 1px solid #1976d2 !important;
}

.section-metadata {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(1em, auto);
  @media screen and (max-width: 820px) {
    grid-template-columns: 1fr 1fr auto;
  }

  p {
    margin-bottom: 0;
  }

  *.label {
    // grid-column: span 1;
    grid-column-start: 1;
  }
  *.detail {
    max-height: 3em;
    // grid-column: span 2;
    grid-column-end: 3;
    justify-self: end;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-width: 110%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    &.-edit {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 8px;

      *.detail-input {
        flex: 1;
      }
    }
    &.-max-content {
      max-height: max-content !important;
    }
  }
}
.-icon {
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}
</style>
