<template>
  <v-breadcrumbs class="-header-space" light :items="currentPath">
    <template v-slot:divider>
      <v-icon size="20" light>mdi-play</v-icon>
    </template>
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :href="item.href"
        :disabled="item.disabled"
        @click="onClick(item)"
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  props: {
    currentPath: {
      type: Array,
      default: () => [{ text: "My Dataset", disabled: true }],
    },
  },
};
</script>

<style lang="scss" scoped>
.v-breadcrumbs {
  padding-left: 0;
}
</style>
