<template>
  <div class="directory">
    <v-treeview
      :active.sync="active"
      :items="items"
      :load-children="myDataset"
      :open.sync="open"
      item-children="child"
      activatable
      color="#000"
      transition
    >
      <template v-slot:prepend="{ item }">
        <img
          src="@/assets/data.svg"
          v-if="item.type === `dataset`"
          :width="20"
        />
        <img
          v-else-if="item.type === `account`"
          src="@/assets/user.svg"
          width="20"
        />
        <img
          v-else-if="item.type === `share-variant`"
          src="@/assets/shared.svg"
          width="20"
        />
        <img v-else src="@/assets/folder.svg" width="20" />
        <!-- <v-icon v-else> mdi-{{ item.type ? item.type : "folder" }} </v-icon> -->
      </template>
    </v-treeview>
  </div>
</template>

<script>
import { mapState } from "vuex";
const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default {
  data: () => ({
    active: [],
    open: [],
    users: [],
    directory: [],
  }),

  computed: {
    items() {
      return [
        {
          id: 0,
          name: "My Dataset",
          child:
            this.directory.length > 0
              ? this.directory
              : this.$store.state.myDataset,
          type: "account",
          size: 32,
        },
        {
          id: 99,
          name: "Shared",
          type: "share-variant",
          size: 32,
        },
      ];
    },
    selected() {
      if (!this.active.length) return undefined;
      const id = this.active[0];

      return this.$store.state.myDataset.find((dataSet) => dataSet.id === id);
    },
    ...mapState({
      directoryTree: (state) => state.directoryTree,
      // activeVuex: (state) => state.activeVuex,
    }),
  },

  watch: {
    selected: {
      immediate: true,
      handler() {
        console.log("selected", this.selected);
        this.selected.id === 0
          ? this.$store.dispatch("getMydataset")
          : this.$store.dispatch("getTree", this.selected.id);

        // this.selected.directory != this.selected.id
        //   ? this.$store.dispatch("getDirectory", this.selected.directory)
        //   : this.$store.dispatch("getDirectory", this.selected.id);

        // this.$store.dispatch("onExplorerChange", {
        //   type: this.selected.type,
        //   id: this.selected.id,
        // });
        switch (this.selected.type) {
          case "folder":
            this.$store.dispatch("getMetadataByID", {
              type: 1,
              id: this.selected.id,
            });
            break;
          case "file":
            this.$store.dispatch("getMetadataByID", {
              type: 2,
              id: this.selected.id,
            });
            break;
          case "dataset":
            this.$store.dispatch("getMetadataByID", {
              type: 0,
              id: this.selected.dataset_id,
            });
            break;
          default:
            null;
        }
        // this.$store.dispatch("getDirectory", this.selected.id);
        // this.selected?.directory
        //   ? this.$store.dispatch("getDirectory", this.selected.directory)
        //   : this.$store.dispatch("getDirectory", this.selected.id);
      },
    },
    active: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          if (val[0] == 99) {
            this.$store.dispatch("getShared");
            this.$router.push({
              name: "sharewithme",
              params: {
                id: val[0],
              },
            });
          } else {
            let payloadData = {
              id: val[0],
              path: "mydataset",
            };
            this.$router.push({
              name: "mydataset",
              params: {
                id: val[0] === undefined ? 0 : val[0],
              },
            });
            this.$store.dispatch("getDirectory", payloadData);
          }
        }

        // this.active[0] != this.active[0]
        //   ? this.$store.dispatch("getDirectory", this.active[0])
        //   : null;
      },
    },
    // activeVuex: {
    //   immediate: true,
    //   handler() {
    //     console.log("activeVuex", this.activeVuex);
    //     this.active = [...this.active, this.activeVuex];
    //   },
    // },
    directoryTree: {
      immediate: true,
      handler() {
        console.log("inCompo", this.directoryTree);

        this.directory = this.directoryTree;
      },
    },
  },

  methods: {
    async myDataset() {
      // Remove in 6 months and say
      // you've made optimizations! :)
      await pause(1500);

      return this.$store.dispatch("getMydataset");

      // return fetch("https://jsonplaceholder.typicode.com/users")
      //   .then((res) => res.json())
      //   .then((json) => item.children.push(...json))
      //   .catch((err) => console.warn(err));
    },
  },
  mounted() {
    this.$store.dispatch("getMydataset");
  },
};
</script>

<style></style>
