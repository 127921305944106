// Type = 0:Dataset 1:Folder 2:File

export default {
  methods: {
    async mapSearchResult(data) {
      console.log("get data", data);
      let array = [];
      const { me, other } = data;
      for (const [key, searchResultList] of Object.entries(me)) {
        console.log(
          "T2- Mixin search searchResultList:",
          searchResultList,
          key
        );
        const type = key === "dataset" ? 0 : key === "directory" ? 1 : 2;
        searchResultList.forEach((item) => {
          console.log("T2 type:", item, item?.parent_dir && key !== "file");
          // console.log('array val:', item)
          // console.log('directory id:', item.type?.id || null)
          const newItem = {
            id: item.directory?.id || item.id,
            [key === "dataset" ? "dataset_id" : null]: item.id,
            name: item.name,
            // type: item.parent_dir > 0 ? 1 : key === "file" ? 2 : 0,
            type: type,
            file_type: item.type?.id || null,
            last_modified: item.last_modified || item.directory.last_modified,
            owner: item.owner.user,
            from: "mydataset",
          };
          array.push(newItem);
          // console.log('newItem:', newItem)
        });
        console.log("T2- Mixin results:", array);
      }
      for (const [key, val] of Object.entries(other)) {
        const type = key === "dataset" ? 0 : key === "directory" ? 1 : 2;
        val.forEach((item) => {
          // console.log('array val:', item)
          // console.log('directory id:', item.type?.id || null)
          const newItem = {
            id: item.directory?.id || item.id,
            [key === "dataset" ? "dataset_id" : null]: item.id,
            name: item.name,
            // type: item.parent_dir > 0 ? 1 : key === "file" ? 2 : 0,
            type: type,
            file_type: item.type?.id || null,
            last_modified: item.last_modified || item.directory.last_modified,
            owner: item.owner.user,
            from: "sharewithme",
          };
          array.push(newItem);
          // console.log('newItem:', newItem)
        });
        // console.log(key, val)
      }
      // data.forEach(element => {
      // })
      // const newItem = {
      //   "id": 2,
      //   "name": "a_file_csv_me_01_test",
      //   "type": 2,
      //   "file_type": ".csv",
      //   "last_modified": "2022-03-24T04:59:43.188403Z",
      //   "owner": "Me"
      // }
      // console.log('last:', array)
      return array;
    },
  },
};
