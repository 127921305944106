<template>
  <div class="directory-content">
    <v-data-table
      class="elevation-1 flex-grow-1"
      :headers="headers"
      :items="directory"
      :items-per-page="-1"
      :loading="dirLoading"
      hide-default-footer
      height="800"
      fixed-header
    >
      <template v-slot:item="{ item }">
        <tr
          @click="onClick(item)"
          @dblclick="onDblClick(item)"
          @contextmenu="rightClick($event, item)"
          :class="{
            primary: `${item.id}${item.type}` === selectedId,
          }"
        >
          <td>{{ item.id }}</td>
          <td class="t-icon">
            <img
              v-if="item.name.split('.')[1] === 'parquet'"
              src="@/assets/parquet.svg"
              width="20"
              height="20"
            />

            <img
              v-else-if="
                resolveItemType(item.type, item.file_type) === 'folder'
              "
              src="@/assets/folder.svg"
              width="20"
              height="20"
            />
            <v-icon
              v-else-if="resolveItemType(item.type, item.file_type) === 'file'"
              width="20"
              height="20"
            >
              mdi-file
            </v-icon>
            <img v-else src="@/assets/data.svg" width="20" align="center" />
            {{ item.name }}
          </td>
          <td class="d-none d-lg-table-cell">
            {{ formatDate(item.last_modified) }}
          </td>
          <td class="d-none d-lg-table-cell">{{ item.owner }}</td>
          <td align="end" class="d-none d-lg-table-cell">
            {{ item.human_size }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      transition="slide-y-transition"
    >
      <v-list>
        <v-list-item link @click="onDelete($event, itemed)">
          <v-list-item-title>Delete</v-list-item-title>
        </v-list-item>

        <v-divider :style="{ display: validDownload }" />
        <v-list-item
          :style="{ display: validDownload }"
          link
          @click="onDownload($event, itemed)"
        >
          <v-list-item-title>Download</v-list-item-title>
        </v-list-item>

        <v-divider :style="{ display: validS3key }" />
        <v-list-item
          :style="{ display: validS3key }"
          link
          @click="getS3Key($event, itemed)"
        >
          <v-list-item-title>Get S3key</v-list-item-title>
        </v-list-item>

        <v-divider :style="{ display: validShared }" />
        <v-list-item
          :style="{ display: validShared }"
          link
          @click="sharedModal($event, itemed)"
        >
          <v-list-item-title>Share</v-list-item-title>
        </v-list-item>
        <!-- <v-divider />
				<v-list-item link>
					<v-list-item-title @click="dataDictModal($event, itemed)"
						>Create DataDictionary</v-list-item-title
					>
				</v-list-item>
				<v-divider />
				<v-list-item link>
					<v-list-item-title @click="profilingModal($event, itemed)"
						>Create Profiling</v-list-item-title
					>
				</v-list-item> -->
      </v-list>
    </v-menu>
  </div>
</template>

<script>
// const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export default {
  props: {
    directory: Array,
    isLoading: Boolean,
    file_path: String,
  },
  data: () => ({
    headers: [
      { text: "Id", value: "id", width: "65" },
      {
        text: "Name",
        align: "start",
        value: "name",
      },
      {
        text: "Last modefied",
        value: "last_modified",
        align: " d-none d-lg-table-cell",
      },
      { text: "Owner", value: "owner", align: " d-none d-lg-table-cell" },
      {
        text: "Size",
        value: "human_size",
        align: "end d-none d-lg-table-cell",
      },
    ],
    delay: 250,
    timer: null,
    clicks: 0,
    selectedId: -1,
    showMenu: false,
    x: 0,
    y: 0,
    itemed: {},
    validDownload: "none",
    validShared: "none",
    validS3key: "none",
    dirLoading: false,
  }),
  methods: {
    // TODO: Click Here
    onClick(item) {
      this.clicks++;
      var self = this;
      if (this.clicks === 1) {
        this.timer = setTimeout(function () {
          console.log("Click", item);
          self.clicks = 0;
          self.selectedId = `${item.id}${item.type}`;
          let itemType;

          if (typeof item.type == "number") {
            switch (item.type) {
              case 0:
                itemType = "database";
                break;
              case 1:
                itemType = "folder";
                break;
              case 2:
                itemType = "file";

                break;
            }
          } else {
            itemType = item.type;
          }
          console.log("Click type", itemType);
          switch (itemType) {
            case "folder":
              self.$store.dispatch("getMetadataByID", {
                type: 1,
                id: item.id,
              });

              break;
            case "file":
              self.$store.dispatch("getMetadataByID", {
                type: 2,
                id: item.id,
              });

              break;
            default:
              self.$store.dispatch("getMetadataByID", {
                type: 0,
                id: item?.dataset_id,
              });
          }
        }, this.delay);
      } else {
        clearTimeout(this.timer);
        this.clicks = 0;
      }
    },
    // TODO: Double Click here
    onDblClick(item) {
      console.log("dbclick", item);
      this.selectedId = `${item.id}${item.type}`;
      // TODO: List type of file
      const type_list = ["txt", "csv", "json"];
      // this.dirLoading = true;
      let directoryPayload = {
        id: item.id,
        path: this.$route.name,
      };
      const toDataDict = this.$router.resolve({
        name: "datadictionary",
        params: { id: item.id },
      });
      const toFileViewer = this.$router.resolve({
        name: "fileviewer",
        params: { id: item.id, filepath: this.file_path },
      });

      const file_realType = item.name.split(".")[1];

      if (typeof item.type == "number") {
        switch (item.type) {
          case 0:
            this.$store.dispatch("getMetadataByID", {
              type: 0,
              id: item?.dataset_id,
            });
            this.$store.dispatch("getDirectory", directoryPayload);
            if (this.$route.name === "search") {
              this.$router.push({
                name: item.from,
                params: {
                  id: item.id,
                },
              });
            } else {
              this.$router.push({
                name: this.$route.name,
                params: {
                  id: item.id,
                },
              });
            }
            break;
          case 1:
            this.$store.dispatch("getMetadataByID", {
              type: 1,
              id: item.id,
            });
            this.$store.dispatch("getDirectory", directoryPayload);
            if (this.$route.name === "search") {
              this.$router.push({
                name: item.from,
                params: {
                  id: item.id,
                },
              });
            } else {
              this.$router.push({
                name: this.$route.name,
                params: {
                  id: item.id,
                },
              });
            }

            break;
          case 2:
            this.$store.dispatch("getMetadataByID", {
              type: 2,
              id: item.id,
            });
            localStorage.setItem("fileData", JSON.stringify(item));
            item.name.split(".")[1] === "parquet"
              ? window.open(toDataDict.href, "_blank")
              : null;
            break;
        }
      } else if (typeof item.type == "string") {
        switch (item.type) {
          case "folder":
            this.$store.dispatch("getMetadataByID", {
              type: 1,
              id: item.id,
            });
            this.$store.dispatch("getDirectory", directoryPayload);
            this.$router.push({
              name: this.$route.name,
              params: {
                id: item.id,
              },
            });
            break;
          case "dataset":
            this.$store.dispatch("getMetadataByID", {
              type: 0,
              id: item?.dataset_id,
            });
            this.$store.dispatch("getDirectory", directoryPayload);
            this.$router.push({
              name: "mydataset",
              params: {
                id: item.id,
              },
            });
            break;
          case "file":
            this.$store.dispatch("getMetadataByID", {
              type: 2,
              id: item.id,
            });
            if (file_realType === "parquet") {
              localStorage.setItem("fileData", JSON.stringify(item));
              window.open(toDataDict.href, "_blank");
            } else if (type_list.includes(file_realType)) {
              window.open(toFileViewer.href, "_blank");
            } else {
              null;
            }

            break;
        }
      }
    },
    rightClick(e, item) {
      this.itemed = item;
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    async getS3Key(e, item) {
      const response = await this.$store.dispatch(
        "getFileRetrieve",
        parseInt(item.id)
      );
      const { data } = response;

      this.$emit("s3KeyModal", { dialog: true, item_data: data.s3_key });
    },
    onDelete(e, item) {
      console.log("delete", item);
      this.$emit("deleteModal", { dialog: true, item_data: item });
      // this.$store.dispatch("deleteItem", item);

      this.showMenu = false;
      // setTimeout(() => {
      //   let payloadData = {
      //     id: this.$route.params.id,
      //     path: this.$route.name,
      //   };
      //   this.$store.dispatch("getDirectory", payloadData);
      // }, 500);
    },
    onDownload(e, item) {
      console.log("Download This file", item);
      this.$store.dispatch("donwloadFile", item);
      this.showMenu = false;
    },
    formatDate(date) {
      const d = new Date(date);
      return (
        d.toLocaleDateString() +
        " " +
        d.getHours() +
        ":" +
        (d.getMinutes() < 10 ? "0" : "") +
        d.getMinutes()
      );
    },
    closeModal() {
      this.visibleModal = !this.visibleModal;
    },
    sharedModal(e, item) {
      this.$emit("sharedModal", { dialog: true, item_data: item });
    },
    dataDictModal(e, item) {
      console.log("datadict", item);
      this.$store.dispatch("createDatadict", item.id);
    },
    profilingModal(e, item) {
      this.$store.dispatch("createProfiling", item.id);
    },
    resolveItemType(type, file_type) {
      if (file_type != null) {
        return "file";
      } else {
        if (typeof type == "number") {
          switch (type) {
            case 0:
              return "database";
            case 1:
              return "folder";
            case 2:
              return "file";
          }
        } else if (type === "dataset") {
          return "database";
        }
      }

      return type;
    },
  },

  mounted() {
    console.log("route", this.$route.params);
    let payloadData = {
      id: this.$route.params.id,
      path: this.$route.name,
    };
    this.$route.params?.id
      ? this.$store.dispatch("getDirectory", payloadData)
      : null;
  },
  watch: {
    $route: function (to, from) {
      console.log("to", to);
      console.log("from", from);
      const self = this;
      let payloadData = {
        id: to.params.id,
        path: this.$route.name,
      };
      to.params?.id ? self.$store.dispatch("getDirectory", payloadData) : null;
    },
    itemed: {
      handler(val) {
        console.log(val);
        if (val.name.split(".")[1] == "parquet") {
          this.validDownload = "none";
          this.validS3key = "flex";
        } else {
          this.validDownload = "flex";
          this.validS3key = "none";
        }
        if (val.type === "dataset") {
          this.validShared = "flex";

          this.validDownload = "none";
        } else if (val.type === "folder") {
          this.validShared = "flex";
          this.validDownload = "none";
        } else {
          this.validShared = "flex";
        }
      },
    },
    isLoading: {
      handler(val) {
        if (val) {
          this.dirLoading = val;
        } else {
          this.dirLoading = val;
        }
      },
      immediate: true,
    },
    directory: {
      handler() {
        this.dirLoading = false;
        this.$store.commit("SET_LOADING", false);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scope>
.dir-table {
  height: 800px;
  flex: 1;
}
</style>
