<template>
  <div class="data-exampler">
    <!-- <table class="for-print">
      <thead>
        <tr>
          <th v-for="(item, index) in headers" :key="index">
            {{ item.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in newData" :key="index">
          <td v-for="keys in valueKeys" :key="keys">{{ item[keys] }}</td>
        </tr>
      </tbody>
    </table> -->

    <v-sheet v-if="isLoading" :color="`#fff `" class="pa-3">
      <v-skeleton-loader
        class="mx-auto"
        type="table-header, table-thead, table-tbody"
      ></v-skeleton-loader>
    </v-sheet>
    <v-data-table
      v-else
      class="for-web"
      height="529px"
      :headers="headers"
      :items="newData"
      :items-per-page="-1"
      fixed-header
      hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr>
          <td v-for="keys in valueKeys" :key="keys" :align="item[keys].align">
            <h3 v-if="item.length < 3">{{ item[1] }}</h3>
            <h3 v-else>{{ item[keys].value }}</h3>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    Example: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    headers: [],
    valueKeys: [],
    newData: [],
    isLoading: true,
  }),
  methods: {
    formatFractionDigit(val) {
      return val?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    formatFractionDigit2: (val) =>
      val?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    transformExample(data) {
      const formatData = data.map((item) =>
        Object.entries(item).reduce((i, [key, value]) => {
          console.log("EX1 test:", this.formatFractionDigit2(value));
          let typeValue = typeof value;
          let alignMent = typeValue === "number" ? "right" : "left";
          return {
            ...i,
            [i[0]]: {
              value: i[1],
              align: typeof i[1] === "number" ? "right" : "left",
            },
            [key]: {
              value:
                typeValue === "number"
                  ? this.formatFractionDigit(value)
                  : value,
              align: alignMent,
            },
          };
        })
      );
      this.headers = Object.keys(data[0]).map((i) => {
        return {
          text: i,
          value: i,
          align: "center",
        };
      });
      this.valueKeys = Object.keys(data[0]);
      this.newData = formatData;
      if (data.length > 0) {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.isLoading) {
        // Set loading false after 10 seconds
        this.isLoading = false;
      }
    }, 10000);
  },
  watch: {
    Example: {
      handler(val) {
        if (val.length > 0) {
          this.transformExample(val);
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.data-exampler {
  display: initial;
}
</style>
