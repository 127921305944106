<template>
  <v-dialog
    v-model="dialog"
    @click:outside="$emit(`saveDialog`, { dialog: false })"
    max-width="600px"
  >
    <v-card class="mb-4">
      <v-card-title>
        <span class="text-h5">Share to People</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-autocomplete
            v-model="model"
            :items="results"
            :loading="isLoading"
            :search-input.sync="search"
            hide-details
            hide-selected
            return-object
            prepend-inner-icon="mdi-magnify"
            label="Add people. . ."
            item-text="user"
            filled
            single-line
            dense
            @change="onSelect"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Search for share to people
                </v-list-item-title>
              </v-list-item>
            </template>
            <!-- <template v-slot:selection="{ attr, on, item, selected }">
              <v-chip
                v-bind="attr"
                :input-value="selected"
                color="blue-grey"
                class="white--text"
                v-on="on"
              >
                <v-icon left> mdi-bitcoin </v-icon>
                <span v-text="item.user"></span>
              </v-chip>
            </template> -->
            <template v-slot:item="{ item }">
              <v-list-item-avatar
                color="indigo"
                class="text-h5 font-weight-light white--text"
              >
                {{ item.user.charAt(0) }}
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.user"></v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.email"
                ></v-list-item-subtitle>
              </v-list-item-content>
              <!-- <v-list-item-action>
                <v-icon>mdi-bitcoin</v-icon>
              </v-list-item-action> -->
            </template>
          </v-autocomplete>
          <v-list subheader>
            <v-list-item v-for="people in peopleShared" :key="people.id">
              <v-list-item-avatar>
                <v-img
                  v-if="people.avatar"
                  :alt="`${people.user} avatar`"
                  :src="people.avatar"
                ></v-img>
                <v-avatar color="brown" v-else>
                  <span class="white--text text-h5">{{
                    people.user.charAt(0)
                  }}</span>
                </v-avatar>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="people.user"></v-list-item-title>
                <v-list-item-subtitle
                  v-text="people.email"
                ></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
                <div v-if="people.role === 'owner'">Owner</div>
                <v-select
                  v-else
                  v-model="people.role"
                  :items="peopelPermission"
                  menu-props="auto"
                  hide-details
                  single-line
                  solo
                  flat
                  dense
                  style="width: 121px"
                >
                  <template v-slot:append-item>
                    <v-divider />
                    <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="removePeople(people)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <span>Remove</span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- <v-btn  text @click="removePeople(people)">
                      Remove
                    </v-btn> -->
                  </template>
                </v-select>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="blue darken-1" text @click="onSave"> Save </v-btn>
      </v-card-actions>
    </v-card>
    <v-card>
      <v-card-text class="pa-2">
        <v-container>
          <div class="a-center">
            <div class="group-title">
              <v-card-title>
                <span class="text-h5"> Share Permission </span></v-card-title
              >
              <v-card-subtitle class="flex a-baseline j-between">
                <span>
                  ApproveStatus :
                  <v-chip
                    :color="
                      ApproveStatus === `requested public`
                        ? 'orange'
                        : ApproveStatus === `accept public request`
                        ? 'green'
                        : ''
                    "
                    :text-color="
                      ApproveStatus === `requested public`
                        ? 'white'
                        : ApproveStatus === `accept public request`
                        ? 'white'
                        : 'black'
                    "
                  >
                    <v-avatar
                      v-if="ApproveStatus === 'accept public request'"
                      left
                    >
                      <v-icon>mdi-checkbox-marked-circle</v-icon>
                    </v-avatar>
                    {{ ApproveStatus }}</v-chip
                  >
                </span>
                <div class="switch-group">
                  <div>
                    {{ accessPermission === false ? "Private" : "Public" }}
                  </div>
                  <v-switch v-model="accessPermission" inset> </v-switch>
                </div>
              </v-card-subtitle>
              <v-card-text
                v-if="accessPermission == true"
                class="flex a-baseline"
              >
                Link :
                <v-combobox
                  v-model="attachLink"
                  hide-selected
                  multiple
                  small-chips
                  outlined
                  dense
                  append-icon=""
                  hide-details
                ></v-combobox>
              </v-card-text>
            </div>
          </div>
          <!-- <div v-if="accessPermission === false">
            <v-divider />
            <div class="flex a-center">
              <div class="group-title">
                <v-card-title>
                  <span class="text-h5"> Other can search </span></v-card-title
                >
              </div>
              <v-switch v-model="serachPermissions" inset></v-switch>
            </div>
          </div> -->
          <v-divider />
          <div class="a-center">
            <div class="group-title">
              <v-card-title class="justify-space-between">
                <span class="text-h5">Share With Link</span>
                <v-switch v-model="share_link" inset></v-switch>
              </v-card-title>

              <v-card-subtitle v-if="linkBox == true">
                <v-text-field
                  readonly
                  v-model="shareLink"
                  outlined
                  dense
                  hide-details
                >
                  <template slot="append">
                    <v-tooltip top :color="copied ? 'success' : null">
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="copyLink">
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                      <span>{{
                        copied ? "Copied !" : "Copy to clipboard"
                      }}</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-card-subtitle>
            </div>
          </div>
          <!-- <div class="flex a-center">
            <div class="group-title">
              <v-card-title>
                <span class="text-h5">
                  Share to My organization</span
                ></v-card-title
              >
              <v-card-subtitle
                >คณะเศรษฐศาสตร์ มหาวิทยาลัยธรรมศาสตร์
              </v-card-subtitle>
            </div>
            <v-switch v-model="organizationShared" inset></v-switch>
          </div> -->
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// import DSMInput from "@/components/Input/DSMInput.vue";
export default {
  // components: {
  //   DSMInput,
  // },
  props: {
    ApproveStatus: {
      type: String,
      default: "",
    },
    dialog: Boolean,
    peopleShared: {
      type: Array,
      default: () => [
        {
          id: 1,
          name: "John Doe",
          permission: "Viewer",
        },
      ],
    },
    accessPermission: {
      type: Boolean,
      default: false,
    },
    results: {
      type: Array,
      default: () => [],
    },
    itemData: {
      type: Object,
      default: () => {},
    },
    // serachPermissions: {
    //   type: Boolean,
    //   default: false,
    // },
    // organizationShared: {
    //   type: Boolean,
    //   default: false,
    // },
    shareWithLink: {
      type: Boolean,
      default: false,
    },
    shareCode: {
      type: String,
      default: "",
    },
    statusCode: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    isLoading: false,
    items: [],
    model: null,
    search: null,
    tab: null,
    oldaccesPermission: false,
    // accessPermission: false,
    peopelPermission: ["viewer", "editor", "manager"],
    attachLink: [],
    shareLink: "",
    copied: false,
    linkBox: false,
  }),
  computed: {
    searchPermissions: {
      get() {
        return false;
      },
    },
    organizationShared: {
      get() {
        return false;
      },
    },
    share_link: {
      get() {
        return this.shareWithLink;
      },
      set(val) {
        // console.log("computed ", val);

        this.$store.commit("SET_PERMISSTION", null);
        if (val == true) {
          let patchPermission = {
            id:
              this.itemData.type === "dataset"
                ? this.itemData.dataset_id
                : this.itemData.id,
            data: { share_with_link: true },
            type: this.itemData.type,
          };

          this.$store.dispatch("patchFilePermission", patchPermission);
        } else {
          let patchPermission = {
            id:
              this.itemData.type === "dataset"
                ? this.itemData.dataset_id
                : this.itemData.id,
            data: { share_with_link: false },
            type: this.itemData.type,
          };

          this.$store.dispatch("patchFilePermission", patchPermission);
        }

        // let payload = {
        //   id:
        //     this.itemData.type === "dataset"
        //       ? this.itemData.dataset_id
        //       : this.itemData.id,
        //   type: this.itemData.type,
        // };
        // this.$store.dispatch("getfilePermissions", payload);

        if (val == true) {
          this.linkBox = true;
        } else {
          this.linkBox = false;
        }
        return val;
      },
    },
  },
  mounted() {
    // console.log("firstMounted", this.accessPermission);
    this.oldaccesPermission = this.accessPermission;
  },
  watch: {
    search(val) {
      console.log("search", val);
      this.$store.dispatch("searchAccounts", val);
    },

    shareCode: {
      handler(val) {
        this.shareLink = `${window.location.origin}/share-with-link/?code=${val}`;
      },
      immediate: true,
    },
    shareWithLink: {
      handler(val) {
        // console.log("shareWithLink", val);
        if (val == true) {
          this.linkBox = true;
        } else {
          this.linkBox = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    onSelect() {
      console.log("onSelect", this.model);
      this.$store.dispatch("addPeople", this.model);
      this.$nextTick(() => {
        this.model = null;
      });
    },
    onSave() {
      console.log("onSave", this.peopleShared);
      let viewer = [];
      let editor = [];
      this.peopleShared.map((i) => {
        if (i.role === "viewer") {
          viewer.push(i.id);
        } else if (i.role === "editor") {
          editor.push(i.id);
        }
      });

      let payload = {
        id:
          this.itemData.type === "dataset"
            ? this.itemData.dataset_id
            : this.itemData.id,
        data: {
          viewer: viewer,
          editor: editor,
          [this.accessPermission != this.oldaccesPermission
            ? "request_public"
            : null]: this.accessPermission,
          share_with_link: this.shareWithLink,
          attach_links: this.attachLink,
        },
        type: this.itemData.type,
      };
      this.$store.dispatch("patchFilePermission", payload);
      this.$emit(`saveDialog`, { dialog: false });
    },
    copyLink() {
      this.copied = true;
      navigator.clipboard.writeText(this.shareLink);
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },
    removePeople({ id }) {
      console.log("people :", id);
      console.log("peopleShared :", this.peopleShared);
      this.peopleShared = this.peopleShared.filter((i) => i.id !== id);

      // this.$store.dispatch("removePeople", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}
.a-center {
  align-items: center;
  justify-content: space-between;
}
.switch-group {
  display: flex;
  align-items: center;
  gap: 10px;
  // .switch-stat {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: space-between;
  // }
}
</style>
