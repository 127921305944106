<template>
  <v-card
    v-if="show"
    class="d-flex flex-column"
    elevation="3"
    max-width="320"
    min-height="200"
    max-height="300">
    <v-card-title class="pa-2 grey darken-3 d-flex justify-space-between">
      <span class="text-body-1 grey--text text--lighten-5">Uploading {{itemsCount}} items</span>
      <div class="d-flex">
        <v-icon class="grey--text text--lighten-5 mr-1">mdi-chevron-down</v-icon>
        <v-icon class="grey--text text--lighten-5">mdi-close</v-icon>
      </div>
    </v-card-title>
    <v-card-actions class="d-flex flex-wrap flex-grow-1 text-body-2 overflow-auto" max-width="100%" min-height="100%">
      
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'UploadQueue',
  data: () => ({
    show: true,
    itemsCount: 10,
  }),
  computed: {
    ...mapState({
      uploadQueue: (state) => state.uploadQueue,
    }),
  },
}

</script>

<style lang="scss">
</style>