<template>
  <v-list dense>
    <v-list-item-group v-model="selectedItem" color="primary">
      <v-list-item :href="'#' + item.text" v-for="(item, i) in items" :key="i">
        <v-list-item-content>
          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon v-if="selectedItem === i"> mdi-play</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  props: {
    Profiling: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    selectedItem: 0,
    items: [
      {
        text: "Data Dictionary",
        disabled: true,
      },
      {
        text: "Data Example",
        disabled: true,
      },
    ],
  }),
  watch: {
    Profiling: {
      handler(val) {
        console.log("navigate", val);
        val.map((item) => {
          this.items.push({ text: item.column, disabled: true });
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-size: 0.875rem !important;
}
</style>
