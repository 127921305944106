<template>
  <div class="approve-status-frame">
    <v-simple-table class="frame-table" fixed-header height="100%">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">Actor</th>
            <th class="text-center">Actions</th>
            <th class="text-center">Context</th>
            <th class="text-center">Date</th>
          </tr>
        </thead>
        <p class="table-no-data" v-if="ActivityHistory.length == 0">
          Data not found.
        </p>
        <tbody v-else>
          <tr v-for="(item, index) in ActivityHistory" :key="index">
            <td>{{ item.actor.user }}</td>
            <td>{{ item.action }}</td>
            <td>{{ item.context }}</td>
            <td>{{ formatDate(item.timestamp) }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "ApproveStatusFrame",
  props: {
    ActivityHistory: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    sampleData: [
      {
        id: 0,
        date: "12/02/2022 13:12",

        detail: "Accept change",
      },
      {
        id: 1,
        date: "14/02/2022 15:12",

        detail: "Tag not accepted",
      },
      {
        id: 2,
        date: "18/02/2022 19:17",

        detail: "Accept change",
      },
      {
        id: 3,
        date: "23/02/2022 09:02",

        detail: "Accept change",
      },
      {
        id: 4,
        date: "27/02/2022 10:22",

        detail: "File detail not accepted",
      },
      {
        id: 5,
        date: "30/02/2022 13:32",

        detail: "File name not accepted",
      },
      {
        id: 6,
        date: "30/02/2022 21:42",

        detail: "Accept change",
      },
      {
        id: 7,
        date: "30/02/2022 21:42",

        detail: "Accept change",
      },
      {
        id: 8,
        date: "30/02/2022 21:42",

        detail: "Accept change",
      },
      {
        id: 9,
        date: "30/02/2022 21:42",

        detail: "Accept change",
      },
      {
        id: 10,
        date: "30/02/2022 21:42",

        detail: "Accept change",
      },
      {
        id: 11,
        date: "30/02/2022 21:42",

        detail: "Accept change",
      },
      {
        id: 12,
        date: "30/02/2022 21:42",

        detail: "Accept change",
      },
      {
        id: 13,
        date: "30/02/2022 21:42",

        detail: "Accept change",
      },
      {
        id: 14,
        date: "30/02/2022 21:42",

        detail: "Accept change",
      },
      {
        id: 15,
        date: "30/02/2022 21:42",

        detail: "Accept change",
      },
      {
        id: 16,
        date: "30/02/2022 21:42",

        detail: "Accept change",
      },
      {
        id: 17,
        date: "30/02/2022 21:42",

        detail: "Accept change",
      },
    ],
  }),

  computed: {
    reverseSampleData() {
      return this.sampleData.slice(0).reverse();
    },
  },

  methods: {
    formatDate: (date) => new Date(date).toLocaleDateString(),
  },
};
</script>

<style lang="scss">
.approve-status-frame {
  height: 100%;
  position: relative;
  display: flex;
  gap: 20px;
  flex-direction: column;
  .frame-table {
    height: 85% !important;
  }
}
thead {
  tr th {
    font-size: 14px !important;
    color: #000 !important;
  }
}
.table-no-data {
  text-align: center;
  font-size: 14px;
  color: #c4c4c4;
  position: absolute;
  margin: 20px 0;
  width: 100%;
}
tbody {
  cursor: default;

  tr td {
    font-size: 14px !important;
  }
}
</style>
