<template>
  <div class="px-4 py-6">
    <div class="d-flex flex-row justify-space-between">
      <h3>Read me</h3>
    </div>
    <vue-markdown>
      {{ readMe }}
    </vue-markdown>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";

export default {
  name: "ReadMeFrame",
  components: { VueMarkdown },
  // data: () => ({
  //   readMe: ""
  // }),
  props: {
    readMe: String,
  },
};
</script>

<style scoped lang="scss">
.edit-icon {
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}
</style>
