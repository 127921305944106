<template>
  <div>
    <div class="table-header">
      <h3 class="bot-border no-underline">{{ TableTitle }}</h3>
      <v-btn
        class="for-web"
        v-if="disabled == true"
        text
        color="warning"
        @click="editItem"
      >
        <h3 class="bot-border no-underline">Edit</h3>
      </v-btn>
      <v-btn v-else text color="primary" @click="saveItem"> Save </v-btn>
    </div>

    <table class="for-print">
      <thead>
        <tr>
          <th v-for="(item, index) in headers" :key="index">
            {{ item.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in datadic" :key="index">
          <td>
            {{ item.column_name }}
          </td>
          <td>{{ item.display_name }}</td>
          <td>{{ dataTypePrint(dataType, item.data_type).name }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.is_required }}</td>
          <td>{{ item.relation }}</td>
        </tr>
      </tbody>
    </table>
    <v-sheet v-if="isLoading" :color="`#fff `" class="pa-3">
      <v-skeleton-loader
        class="mx-auto"
        type="table-header, table-thead, table-tbody"
      ></v-skeleton-loader>
    </v-sheet>
    <v-data-table
      v-else
      class="dict-table for-web"
      :headers="headers"
      :items="datadic"
      fixed-header
      :items-per-page="-1"
      hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr align="left">
          <td>
            {{ item.column_name }}
          </td>
          <td>
            <v-text-field
              :disabled="disabled"
              filled
              height="40px"
              hide-details
              single-line
              v-model="item.display_name"
            ></v-text-field>
          </td>
          <td>
            <v-select
              filled
              :disabled="disabled"
              v-model="item.data_type"
              :items="dataType"
              item-text="name"
              height="40px"
              item-value="id"
              menu-props="auto"
              hide-details
              single-line
            ></v-select>
          </td>
          <td>
            <v-textarea
              v-model="item.description"
              filled
              :disabled="disabled"
              hide-details
              height="40px"
              auto-grow
              :rules="[
                (v) =>
                  (v || '').length <= 1024 ||
                  'Description must be 1024 characters or less',
              ]"
              rows="2"
              single-line
            ></v-textarea>
          </td>

          <td>
            <v-select
              filled
              :disabled="disabled"
              v-model="item.is_required"
              :items="required"
              menu-props="auto"
              height="40px"
              hide-details
              single-line
            ></v-select>
          </td>
          <td>
            <v-text-field
              v-model="item.relation"
              :disabled="disabled"
              filled
              hide-details
              height="40px"
              single-line
            ></v-text-field>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    TableTitle: String,
    datadic: Array,
    dataType: Array,
  },
  data: () => ({
    timer: null,
    headers: [
      {
        text: "Column Name",
        align: "center",
        value: "column_name",
      },
      {
        text: "Display Name",
        align: "center",
        value: "display_name",
        width: "200",
      },
      {
        text: "Data Type",
        align: "center",
        value: "type.name",
        width: "150",
      },
      {
        text: "Description",
        align: "center",
        value: "description",
        width: "500",
      },
      {
        text: "Required",
        align: "center",
        value: "is_required",
        width: "120",
      },
      {
        text: "Relation",
        align: "center",
        value: "relation",
        width: "200",
      },
    ],
    required: [true, false],
    disabled: true,
    isLoading: true,
  }),
  methods: {
    editItem() {
      // console.log("edit", item);
      this.disabled = !this.disabled;
    },

    saveItem() {
      this.datadic.map((i) => {
        console.log("save", i);
        let payload = {
          column_name: i.column_name,
          data_type: i.data_type.id || i.data_type,
          description: i.description,
          display_name: i.display_name,
          file: i.file,
          id: i.id,
          is_required: i.is_required,
          is_use: i.is_use,
          relation: i.relation,
        };
        this.$store.dispatch("patchDatadictionary", payload);
        // if (i.column_name === item.column_name) {
        //   i.disabled = !i.disabled;
        //   i.editable = !i.editable;
        // }
      });
      this.disabled = !this.disabled;
    },
    dataTypePrint: (data, id_datatype) =>
      data.find((i) => i.id === id_datatype.id || i.id === id_datatype),
  },
  watch: {
    datadic: {
      handler: function (val) {
        console.log("have", val);
        if (val.length > 0) {
          this.isLoading = false;
        }
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          if (this.isLoading) {
            this.isLoading = false;
            if (
              this.datadic.length === 0 &&
              this.$store.state.profiling.example
            ) {
              const errorDataDict = {
                type: "warning",
                title: "Something wrong.",
                body: "Data dictionary is empty.",
              };
              this.isLoading = false;
              this.$store.dispatch("ToastModule/addToast", errorDataDict);
            }
          }
        }, 4000);
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.v-text-field {
  padding-top: 0;
  margin-top: 0;
}
.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.table-header {
  position: relative;
  display: flex;
  align-items: center;
  button {
    font-size: 0.875rem !important;
  }
}
</style>
